.modalFooter {
  flex: 1 1 auto;
  height: 6vh;
  width: 100%;
  padding: 12px;
  overflow: hidden;
  border-style: solid;
  border-width: 0px;
  border-top-width: 0.1px;
  border-image: linear-gradient(135deg, #fff, #000) 1;
}

.modalFooterclose,
.modalFooterapply {
  padding: 8px 24px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.modalFooterapplySatCreation{
  padding: 4px 10px 8px 14px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  right: 12px;
  border-radius: 4px;
  background: #20563d;
  transition: 300ms;
}
.modalFooterclose {
  position: absolute;
  left: 12px;
  background: transparent;
  border-radius: 4px;
  border: 0.5px solid rgba(240, 240, 240, 0.5);
  transition: 300ms;
}

.modalFooterclose:hover {
  background: rgba(240, 240, 240, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(160, 160, 160, 0.2);
}

.modalFooterapply {
  position: absolute;
  right: 12px;  
  transition: 300ms;
  border-radius: 4px;
  background: #45570D;
}

.modalFooterapply:hover {
  background: #45570D;
  box-shadow: 0px 4px 10px 0px rgba(160, 160, 160, 0.2);
}

.disable_analysis_button{
  opacity: 0.4;
}