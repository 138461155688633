.createGroupCardSmall{
  min-width: 400px !important;
    display: flex !important;
    width: 520px !important;
    padding: 24px !important;
    flex-direction: column !important;
    align-items: center;
    gap: 12px !important;
}
.createGroupHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.createGroupBorderBottom {
  padding-bottom: 12px;
  position: relative;
}

.createGroupBorderBottom::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.5px; /* The height of the bottom border */
  background: linear-gradient(to right, #6e6e6e, rgba(110, 110, 110, 0));
  border-radius: 0.5px;
}

.createGroupDetailsFormItem {
  width: calc(50% - 8px);
  min-width: calc(50% - 8px);
  max-width: calc(50% - 8px);
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: var(--8, 8px);
  flex: 1 0 0;
}

.createGroupDetailsFormItem.fullWidth {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}

.rs-picker-toggle-wrapper .rs-picker-toggle.rs-btn,
.createGroupDetailsFormItem input {
  display: flex;
  padding: var(--4, 4px) 12px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: var(--4, 4px);
  border: 0.5px solid rgba(240, 240, 240, 0.3) !important;
  background: rgba(10, 10, 10, 0.65) !important;
  width: 100%;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  min-height: 32px;
}


.createGroupDetailsFormRow {
  /* display: flex; */
  align-items: flex-start;
  gap: var(--16, 16px);
  align-self: stretch;
}
.createGroupDetailsInfoHoverCard{
  color: #F0F0F0;
font-family: Montserrat;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 4px;
background: rgba(204, 245, 78, 0.10);
box-shadow: 0px 2px 4px 0px rgba(240, 240, 240, 0.05);
backdrop-filter: blur(20px);
position: absolute;
right: 0px;
top: 20px;
display: flex;
width: 198px;
padding: 8px;
justify-content: center;
align-items: center;
gap: 10px;
z-index: 9999;
}
.createGroupDetailsFormDivGroup{
  display: flex;
  padding: 12px 12px;
  /* flex-direction: column; */
  gap: 12px;
  align-self: stretch;
  border-radius: var(--8, 8px);
  background: rgba(255, 255, 255, 0.05);
  flex-wrap: wrap;
}
.unitBoxcreateGroup{
  align-self: center;
    height: 32px;
    width: 38px;
    padding: 7px;
    border: solid 1px #F0F0F0;
    BORDER-RADIUS: 0PX 4PX 4PX 0PX;
    border-radius: 0px 4px 4px 0px;
    border: 0.5px solid rgba(240, 240, 240, 0.30);
    background: rgba(10, 10, 10, 0.65);
    color: #f0f0f0;
    font-weight: 400;
    font-size: 12px;
}

.form-control.inputBoxWithUnitcreateGroup {
    border-radius: 4px 0 0 4px !important;
}
/* .inputBoxWithUnitcreateGroup{
  border-radius: 4px 0px 0px 4px !important;
} */
.createGroupDetailsBoxDiv {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-start; */
  align-self: stretch;
}

.createGroupDetailsBoxRow {
  /* display: flex; */
  align-items: flex-start;
  gap: var(--24, 24px);
  max-width: 100%;
  flex-wrap: wrap;
}
.customDescriptionForGroup{
    resize: none !important;
    height: 71px !important;
    border-radius: 4px 0px 0px 4px !important;
    border: 0.5px solid rgba(240, 240, 240, 0.30) !important;
    background: rgba(10, 10, 10, 0.65) !important;
    font-size: 12px !important;
}
.createGroupButtonsDiv {
  width: 100%;
}
.closeModal__btn_createGroup{
  display: flex;
  height: var(--35, 35px);
  padding: 9px var(--24, 24px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: transparent;
  border: 1px solid rgba(240, 240, 240, 0.20);
  color: #F0F0F0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.createAndConfigureModal_btn_createGroup{
    display: flex;
padding: 8px 24px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
background: #45570D;
color: #EEE;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.createAndSaveModal_btn_createGroup{
display: flex;
height: 35px;
padding: 9px 24px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 4px;
border: 1px solid #20563D;
background: transparent;
color: #EEE;
font-family: Montserrat;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.feedBackToastMessage{
  font-size: 10px;
}