.satelliteCreationHeader{
    display: flex;
    padding: 8pt 4pt 8pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: rgba(255, 255, 255, 0.02);
}

.svgContainerSat{
    height: 16pt;
    width: 16pt;
}

.headerText{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.18pt;
}

.headerSubText{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 10pt;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.15pt;
    opacity: 0.5;
}

.creationState{
    display: flex;
    align-items: center;
    gap: 4pt;
}

.addIcon{
    cursor: pointer;
}

.satelliteCreationBody{
    display: flex;
    flex-direction: column;
    height: 95%;
    gap: 1;
    padding: 0px 0px 32px 10px;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    cursor: pointer;
    overflow-y: auto;
}

.bodyText{
    color: #FFF;
    font-family: Montserrat;
    font-size: 12pt;
    font-style: italic;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.18pt;
    opacity: 0.3;
}

.create_satellite_alignment{
    margin-top: 35%;
    display: flex;
    flex-direction: row;
}