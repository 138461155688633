.schedule__container{
    display: flex;
    width: 100%;
    height: 100%;
    gap: 12px;
    justify-content: space-between;
}

.schedule__panel__separator{
    height: 100%;
    width: 1px;
    background: rgba(204, 245, 78, 0.10);
}

.right__schedule__panel__container{
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    overflow: hidden;
    gap: 5px;
    .right__schedule__panel__container__table__header{
        display: flex;
        padding: 8px 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: var(--4, 4px);
        background: rgba(240, 240, 240, 0.06);
        width: 100%;
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .right__schedule__panel__container__table__body{
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        height: calc(100vh - 200px);
        overflow-y: auto;
        scrollbar-width: none;
        padding-bottom: 10px;
        .schedule__details__container{
            width: 100%;
            padding: 8px 12px;
            position: relative;
            display: flex;
            background: rgba(204, 245, 78, 0.05);
            border-radius: 4px;
            .schedule__details__item{
                display: flex;
                align-items: center;
                justify-content: center;
                color: #F0F0F0;
                text-align: center;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            };

            .schedule__details__item:first-child{
                justify-content: start;
                width: 50%;
                gap: 12px;
                .schedule__icons{
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    width: 30px;
                };
                .details__container{
                    display: flex;
                    flex-direction: column;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    text-align: start;
                    gap: 5px;
                    span:nth-child(1){
                        color: #F0F0F0;
                    };
                    span:nth-child(2){
                        color: #A5A5A5;
                        white-space: nowrap;
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            };
            .schedule__details__item:nth-child(2){
                width: 25%;
            };
            .schedule__details__item:nth-child(3){
                width: 25%;
            };
            &.running{
                &::after{
                    content: '';
                    height: 100%;
                    width: 2px;
                    border-radius: 2px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #CCF54E;
                }
            };
        }
        .telecommands__details__container{
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .telecommands__details__header{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 4px;
                padding: 12px 12px;
                .telecommands__details__header__item{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #F0F0F0;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                };
                .telecommands__details__header__item:first-child{
                    justify-content: start;
                    padding-left: 20px;
                    width: 50%;
                };
                .telecommands__details__header__item:nth-child(2){
                    width: 25%;
                };
                .telecommands__details__header__item:nth-child(3){
                    width: 25%;
                };
            };
            .telecommands__details__body__container{
                flex: 1;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 5px;
                .telecommands__details__body{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-radius: 4px;
                    padding: 8px 12px;
                    position: relative;
                    &.running{
                        &::after{
                            content: '';
                            height: 100%;
                            width: 2px;
                            border-radius: 2px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: #CCF54E;
                        }
                    };
                    .telecommands__details__body__item{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #F0F0F0;
                        text-align: center;
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    };
                    .telecommands__details__body__item:first-child{
                        justify-content: start;
                        width: 50%;
                        gap: 12px;
                        .schedule__icons{
                            display: flex;
                            align-items: center;
                            gap: 12px;
                        };
                        .details__container{
                            display: flex;
                            flex-direction: column;
                            font-family: Montserrat;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            text-align: start;
                            gap: 5px;
                            .command__title__container{
                                display: flex;
                                gap: 5px;
                                align-items: center;
                                svg,
                                span{
                                    color: #F0F0F0;
                                    opacity: 0.7;
                                }
                            }
                            span{
                                color: #A5A5A5;
                                white-space: nowrap;
                                width: 100%;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                opacity: 0.6;
                            }
                        }
                    };
                    .telecommands__details__body__item:nth-child(2){
                        width: 25%;
                        span{
                            opacity: 0.6;
                        }
                    };
                    .telecommands__details__body__item:nth-child(3){
                        width: 25%;
                        span{
                            opacity: 0.6;
                        }
                    };
                }
            }
        }
    }
}

.left__schedule__panel__container,
.right__schedule__panel__container{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 25%;
    gap: 10px;
    .left__schedule__list__panel__container__header{
        display: flex;
        width: 100%;
        padding: 0px 6px 0px 12px;
        justify-content: space-between;
        align-items: center;
        border-radius: 4px;
        background: rgba(240, 240, 240, 0.06);
        span{
            color: #F0F0F0;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        .button__section{
            display: flex;
            align-items: center;
            max-width: 100px;
            button{
                display: flex;
                height: 30px;
                padding: var(--8, 8px) 9px;
                justify-content: center;
                align-items: center;
                gap: var(--8, 8px);
                align-items: center;
                background: transparent;
                &:disabled{
                    opacity: 0.5;
                    cursor: default;
                    pointer-events: none;
                }
            }
        }
    }
    .left__schedule__panel__container__header,
    .right__schedule__panel__container__header{
        display: flex;
        height: 55px;
        padding: var(--8, 8px) 12px;
        justify-content: space-between;
        align-items: center;
        gap: var(--4, 4px);
        align-self: stretch;
        border-radius: var(--4, 4px);
        border: 0.5px solid rgba(204, 245, 78, 0.60);
    };
    .left__schedule__panel__container__header {
        flex-direction: column;
        align-items: flex-start;
        gap: 0px;
        .header__title{
            color: #CCF54E;
            text-align: center;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            white-space: nowrap;
            text-align: start;
        }
        .header__time__details{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span{
                color: #F0F0F0;
                text-align: center;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                &:nth-child(1){
                    opacity: 0.6;
                }
            }
        }
    }
    .right__schedule__panel__container__header{
        color: #F0F0F0;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        .header__title__details__container{
            display: flex;
            align-items: center;
            justify-content: center;
        };
        .header__title__details{
            display: flex;
            flex-direction: column;
            gap: 5px;
            text-align: start;
            max-width: 200px;
            .header__subtitle{
                font-size: 10px;
                opacity: 0.6;
            }
        };
        .header_badge{
            display: flex;
            height: 30px;
            padding: 0px 12px;
            justify-content: center;
            align-items: center;
            gap: 5px;
            border-radius: 6px;
            background: rgba(240, 240, 240, 0.05);
            span{
                color: #F0F0F0;
                text-align: center;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                
            }
        }
    }


    .left__schedule__panel__container__table__header,
    .right__schedule__panel__container__table__header{
        display: flex;
        width: 100%;
        padding: 8px 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: var(--4, 4px);
        background: rgba(240, 240, 240, 0.06);
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    };
    .left__schedule__panel__container__table__body,
    .right__schedule__panel__container__table__body{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 5px;
        height: calc(100vh - 280px);
        overflow-y: auto;
        scrollbar-width: none;
        .schedule__details__container__separator{
            width: 100%;
            height: 1px;
            background: rgba(240, 240, 240, 0.20);
        }
        .schedule__details__container{
            width: 100%;
            padding: 8px 12px;
            position: relative;
            display: flex;
            gap: 12px;
            cursor: pointer;
            .schedule__icons{
                display: flex;
                align-items: center;
                gap: 12px;
            }
            .details__container{
                color: #F0F0F0;
                display: flex;
                flex-direction: column;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                text-align: start;
                width: 95%;

                .details__container__name{
                    width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &.aborted,
                    &.expired{
                        text-decoration: line-through;
                        color: #A5A5A5;
                    }
                }
                .details__container__sub_name,
                .details__container__time{
                    width: 10px;
                    width: 65%;
                    color: #A5A5A5;
                }
                .details__container__status{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 12px;
                    &.aborted,
                    &.expired{
                        color: #C91818;
                    }
                    &.ongoing{
                        color: #CCF54E;
                    }
                }
                .details__container__button__container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    .details__container__button{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: var(--24, 24px);
                        height: var(--24, 24px);
                        background: transparent;
                    }
                }
            }
            &.active{
                background: rgba(204, 245, 78, 0.05);
                border-radius: 4px;
            }
            &.active.running{
                background: rgba(204, 245, 78, 0.05);
                border-radius: 4px;
                &::after{
                    content: '';
                    height: 100%;
                    width: 2px;
                    border-radius: 2px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #CCF54E;
                }
            }
            &.running{
                &::after{
                    content: '';
                    height: 100%;
                    width: 2px;
                    border-radius: 2px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #CCF54E;
                }
            };
        }
    }
}