.satellite_config_item{
    display: flex;
    padding: var(--12, 12pt) var(--12, 12pt) 4pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 12pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}