.ground_station_container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.ground_station_main_header{
    display: flex;
    height: 23pt;
    padding: 0px 0px 0px 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 4pt;
}

.ground_station_main_header:hover{
    background: rgba(204, 245, 78, 0.05);
    border-radius: 4px;
    .ground_station_right_panel{
        display: flex;
    }
}


.selected{
    background: rgba(122, 179, 255, 0.07);
}

.ground_station_left_panel{
    display: flex;
    align-items: center;
    gap: 4pt;
    width: 90%;
    span{
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; 
        color: #F0F0F0;
        font-family: Montserrat;
        line-height: normal;
        letter-spacing: -0.18pt;
        cursor: pointer;
    }
    svg{
        height: 14px;
        width: 14px;
    }
}

.ground_station_right_panel{
    width: 16pt;
    cursor: pointer;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 4pt;
}

.icon_alignment{
    display: flex;
    width: 16pt;
    height: 16pt;
    justify-content: center;
    align-items: center;
}

.disablling{
    visibility: hidden;
}

.menu_item_001{
    position: absolute;
    display: flex;
    padding: 4pt 0pt;
    align-items: center;
    align-self: stretch;
    gap: 4pt;
    span{
        color: #F0F0F0;
        text-align: center;
        font-family: Montserrat;
        font-size: 10pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.gs_menu_item_container{    
    position: absolute;
    display: inline-flex;
    padding: 8pt 4pt;
    flex-direction: column;
    align-items: flex-start;
    gap: 4pt;
    margin: 120pt;
    border-radius: 4pt;
    border: 0.5pt solid rgba(240, 240, 240, 0.10);
    background: rgba(9, 9, 9, 0.50);
    backdrop-filter: blur(20pt);
    margin: -120pt;
}

.menu_option_item{
    display: flex;
    padding: 4pt 0pt;
    align-items: center;
    align-self: stretch;
    border-radius: 4pt;
    gap: 4pt;
    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 10pt;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}