
.settingOrbitLayer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 40px;
    position: absolute;
    right: 50px;
    bottom: 100px;
    overflow: hidden;
    height: 310px;
}
.settingOrbitLayerContainer{
    display: flex;
    flex-direction: column;
}
.settingOrbitLayer .SettingOrbitButtonHandler,
.settingOrbitLayer .settingOrbitLayerContainer .SettingOrbitButtonStyle{
    position: absolute;
    right: 0;
    bottom: 0;
    height: 36px;
    width: 36px;
    padding: 7px;
    background-color: #101923;
    display: flex;
    border-radius: 5px;
    border: 0.5px solid rgba(240,240,240,0.6);
    align-items: center;
    justify-content: center;
    transform: translateY(0px);
    transition: all 0.3s ease-in-out;
    z-index: 99;
}

.settingOrbitLayer .SettingOrbitButtonHandler:hover,
.settingOrbitLayer .settingOrbitLayerContainer .SettingOrbitButtonStyle:hover{
    border: 1px solid #CCF54E;
}
.settingOrbitLayer .SettingOrbitButtonHandler.active:hover
.settingOrbitLayer .settingOrbitLayerContainer .SettingOrbitButtonStyle.active:hover,
.settingOrbitLayer .settingOrbitLayerContainer .SettingOrbitButtonStyle.active{
    border: 1px solid #CCF54E;
}
.settingOrbitLayer.active { 
    width: 350px;
    .settingOrbitLayerContainer > :nth-child(1){
        transform: translateY(-65px)
    };
    .settingOrbitLayerContainer > :nth-child(2){
        transform: translateY(-115px)
    };
    .settingOrbitLayerContainer > :nth-child(3){
        transform: translateY(-165px)
    };
    .settingOrbitLayerContainer > :nth-child(4){
        transform: translateY(-215px)
    };
    .settingOrbitLayerContainer > :nth-child(5){
        transform: translateY(-265px)
    };
    .SettingOrbitButtonHandler{
        border: 1px solid #CCF54E;
    }
}
.settingOrbitDetailsLayer{
    position: absolute;
    width: 0px;
    height: 0px;
    display: flex;
    bottom: 0;
    right: 50px;
    transform: translateX(500px) translateY(500px);
    border-radius: 8px;
    padding: 15px 10px;
    flex-direction: column;
    gap: 14px;
    border: 1px solid #8c8282;
    background: rgba(0, 0, 0, 0.50);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.19);
    backdrop-filter: blur(5px);
    transition: 0.3s ease-in-out;
    z-index: 99;
}
.settingOrbitDetailsLayer.active{
    transform: translateX(0);
    transform: translateY(0);
    height: 300px;
    width: 300px;
    z-index: 99;
}

