.constellation_tree_main{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 2pt;
    width: 100%;
}

.tree_constellation_tree_main_header{
    width: 100%;
    display: flex;
    height: var(--24, 24pt);
    padding-left: 0pt 4pt;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    position: relative;
}

.tree_constellation_tree_main_header:hover {
    background: rgba(204, 245, 78, 0.05);
    border-radius: 4px;
}


.left_heder_section{
    display: flex;
    align-items: center;
    gap: 4pt;
    width: 90%;
}

.left_heder_section_title{
    width: 75%;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis;
    color: #F0F0F0;
    font-family: Montserrat;
    line-height: normal;
    letter-spacing: -0.18pt;
}

.left_header_button{
    transform: rotate(-90deg);
    transition: all 0.3s ease;
}

.constellation_tree_main.active{
    .left_header_button {
        transform: rotate(0deg);
    }
    .tree_constellation_tree_main_body{
        display: flex; 
    }
}

.right_header_spinner {
    padding: 0;
    height: 16pt;
    width: 16pt;

    .MuiCircularProgress-root {
        height: 14px !important;
        width: 14px !important;
    }
}

.right_header_button{
    display: none;
    width: 16pt;
    height: 16pt;
    padding: 1pt 5pt;
    justify-content: start;
    align-items: center;
    gap: 10pt; 
}
.right_header_button.show,
.right_header_button:hover{
    display: flex;
}

.tree_constellation_tree_main_body{
    display: none;
    flex-direction: column;
    gap: 2pt;
    padding: 0pt 4pt 0pt 15pt;
    width: 100%;
}

.tree_constellation_tree_main_body_item{
    width: 100%;
    display: flex;
    height: var(--24, 24pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    gap: 4pt;
    span {
        overflow: hidden;
        white-space: nowrap; 
        text-overflow: ellipsis;
        color: #F0F0F0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.18pt;
        width: 90%;
        cursor: pointer;
    }
}
