.filequeue__panel__container{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid rgba(240, 240, 240, 0.20);
    background: rgba(0, 0, 0, 0.40);
    backdrop-filter: blur(50px);
    border-radius: 8px;
    justify-content: space-between;
    height: 100%;
    width: 350px;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
    &.expand{
        width: 800px;
        .filequeue__panel__header {
            .panel__colapse__btn{
                transform: rotate(180deg);
            }
        } 
    };
    .filequeue__panel__header{
        display: flex;
        padding: var(--8, 8px);
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-bottom: 0.5px solid rgba(240, 240, 240, 0.20);
        .panel__header__title{
            color: #CCF54E;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        };
        .panel__close__btn,
        .panel__pin__btn,
        .panel__colapse__btn{
            background: transparent;
            transform: rotate(0deg);
            transition: all 0.3s ease;
            display: flex;
            width: var(--24, 24px);
            height: var(--24, 24px);
            padding: var(--4, 4px);
            flex-direction: column;
            justify-content: center;
            align-items: center;
        };
        .panel__pin__btn.pinned{
            border-radius: var(--4, 4px);
            background: rgba(204, 245, 78, 0.10);
            svg{
                transform: rotate(-45deg);
                stroke: #CCF54E;
                stroke-width: 0.5px;
                transition: all 0.3s ease;
            }
        }
    };
    .filequeue__panel__body{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        width: 100%;
        flex: 1;
        overflow: hidden;
        .file__download__section,
        .file__upload__section{
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 8px 12px;
            overflow-y: hidden;
            gap: 8px;
            .file__upload__scroll__section,
            .file__download__scroll__section{
                flex: 1;
                width: 100%;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                -ms-overflow-style: none; 
                scrollbar-width: none;
                gap: 8px;
                .file__details__container{
                    width: 100%;
                    display: flex;
                    align-items: start;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 8px 12px;
                    border-radius: var(--4, 4px);
                    background: rgba(240, 240, 240, 0.10);
                    transition: all 0.3s ease;
                    color: #F0F0F0;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    .file__details__header{
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .file__title__wrapper{
                            display: flex;
                            flex-direction: column;
                            gap: 5px;
                            color: #F0F0F0;
                            font-family: Montserrat;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                        }
                        .file__title{
                            overflow: hidden;
                            align-self: stretch;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: 95%;
                            font-size: 12px;
                            opacity: 1;
                        }
                        .file__time{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            max-width: 95%;
                            font-size: 10px;
                            opacity: 0.6;
                        }
                    };
                    .file__queue__btn{
                        height: 25px;
                        width: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: transparent;
                        border-radius: 4px;
                    }
                    &.downloading,
                    &.ongoing{
                        border-radius: var(--4, 4px);
                        background: rgba(204, 245, 78, 0.10);
                    };
                    &.active,
                    &.active.ongoing{
                        // height: 300px;
                        border-radius: var(--4, 4px);
                        background: rgba(0, 0, 0, 0.30);
                        border: none;
                        .file__details__header .icon{
                            color: #CCF54E;
                        }
                        .file__details__body{
                            height: 360px;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            padding: 8px;
                            overflow-y: auto;
                            -ms-overflow-style: none; 
                            scrollbar-width: none;
                        }
                    }
                }
            }
        };
        .file__upload__section{
            border-bottom: 0.5px solid rgba(240, 240, 240, 0.20);
            position: relative;
            .resize__handler{
                height: 1px;
                width: 100%;
                background: rgba(204, 245, 78, 0.10);
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                cursor: row-resize;
                bottom: 0;
                left: 0;
                &:hover{
                    height: 2px;
                    background: rgb(154, 120, 245);
                }
            }
        };
    }
}
