.ordersBar {
    display: inline-flex;
    height: calc(100% - 105px);
    width: 320px;
    padding-top: 30px;
    flex-direction: column;
    flex-shrink: 0;
    background: #0d0d0e;
    box-shadow: 10px 0px 106px 0px rgba(188, 188, 188, 0.25);
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    position: absolute;
    top: 50px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
}

.ordersBar.expandedOrdersBar {
    width: 750px !important;
}

.blackHeaderBar {
    background: transparent;
}

.ordersGreenHeaderBar {
    padding: 8px 12px;
    background: #202414;
    display: flex;
    justify-content: space-between;
}

.ordersGreenHeaderBar.singleOrderView {
    justify-content: flex-start
}

.ordersGreenHeader {
    color: #f0f0f0;
    font-size: 14px;
}

.ordersGreenHeaderBar button {
    background: transparent;
    transition: 300ms;
}

.addNewOrderBtn {
    padding: 4px 8px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #19191a !important;
    font-size: 12px;
    margin-right: 12px;
}

.ordersGreenHeaderBar button:hover {
    scale: 1.2;
}

.orderDescriptionBox {
    height: auto;
    width: 350px;
    padding: 12px;
    border-radius: 8px;
    border: 0.5px solid rgba(240, 240, 240, 0.2);
    background: rgba(15, 15, 15, 0.69);
    backdrop-filter: blur(5px);
    position: absolute;
    /* top: 90px;
    left: 330px; */
    bottom: 60px;
    right: 15px;
    z-index: 100;
}

.priorityIcon {
    float: left;
    position: relative;
    top: 10px;
    margin-right: 10px;
}

.priorityIcon.lowPriorityIcon {
    left: 5px;
}

.priorityIcon.lowPriorityIcon.lowerPlaced {
    top: 5px;
}

.orderTitleName.delayedBox {
    width: auto;
    text-align: left;
    float: left;
}

.orderTitleSubName.delayedBox {
    width: auto;
    text-align: right;
    float: right;
}

.orderTitleDiv.delayedBox {
    width: 100%;
    display: block;
}


.orderDescriptionBoxHeader .priorityIcon,
.orderDescriptionBoxHeader .orderTitleName {
    top: 0px;
    font-size: 14px;
}

.orderDescriptionBoxHeader .priorityIcon {
    scale: 1.3;
}

.orderDescriptionBoxHeaderDiv .recurringIcon.pauseIcon {
    top: 0px;
    scale: 1.2;
}

.orderDescriptionBoxDatesDiv {
    display: flex;
    padding: 12px 0px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 0.5px solid rgba(240, 240, 240, 0);
}

.orderDescriptionBoxDatesTitle {
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.orderDescriptionBoxDatesSubTitle {
    color: rgba(240, 240, 240, 0.6);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.hoverBoxNotification .orderDescriptionBoxDatesTitle,
.hoverBoxNotification .orderDescriptionBoxDatesSubTitle {
    font-size: 9px;
}

.orderDescriptionBoxBodyHeader {
    color: var(--Active-Green, #ccf54e);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.orderDescriptionBoxBodyStatus {
    border-radius: 4px;
    background: rgba(240, 240, 240, 0.1);
    padding: 4px 6px;
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.orderDescriptionBoxLabel {
    min-width: 140px;
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    float: left;
}

.orderDescriptionBoxValue {
    width: calc(100% - 140px);
    color: #f0f0f0b8;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    float: right;
}

.ordersTypeHeaderBar {
    height: 60px;
    width: 100%;
    padding: 12px 12px 0px 0px;
    display: block;
    transition: 300ms;
}

.ordersTypeHeader.margin {
    padding: 6px 12px;
}

.ordersTypeHeaderBar.activeHeaderBar:hover {
    background: rgba(255, 255, 255, 0.2);
}

.ordersTypeHeaderBar.blackHeaderBar:hover {
    background: rgba(255, 255, 255, 0.2);
}

.ordersTypeHeaderBar.warningBar {
    border-left: 2px solid #f33;
    background: rgba(255, 51, 51, 0.1);
}

.subOrdersTypeHeaderBar {
    height: 60px;
    width: 100%;
    padding: 12px 12px 0px 0px;
    display: block;
    transition: 300ms;
}

.subOrdersTypeHeader.margin {
    padding: 6px 12px;
}

.subOrdersTypeHeaderBar.activeHeaderBarSubOrders {
    background: rgba(255, 255, 255, 0.2);
}

.subOrdersTypeHeaderBar.activeHeaderBarSubOrders:hover {
    background: rgba(255, 255, 255, 0.4);
}

.subOrdersTypeHeaderBar.blackHeaderBarSubOrders:hover {
    background: rgba(255, 255, 255, 0.4);
}

.subOrdersTypeHeaderBar.warningBar {
    border-left: 2px solid #f33;
    background: rgba(255, 51, 51, 0.1);
}

.subOrderImg {
    margin-right: 6px;
    opacity: 0.65;
    float: left;
    position: relative;
    top: 2px;
}

.ordersDropdownArrow {
    background: transparent;
    height: 24px;
    padding: 0px 12px 0px 18px;
    float: left;
    transition: 300ms;
}

.ordersDropdownArrow.activeArrow {
    transform: rotate(90deg);
}

.ordersTypeHeader {
    color: rgba(240, 240, 240, 0.8);
    font-size: 14px;
    /* text-transform: uppercase; */
    float: left;
    line-height: 2;
    font-weight: 400;
    letter-spacing: 0.56px;
}

.orderTitleDiv {
    min-width: 200px;
    width: auto;
    display: block;
    float: left;
    padding-left: 12px;
}

.orderDescriptionBoxTitleDiv {
    padding-left: 0px;
}

.orderDescriptionBoxTitleDiv .orderTitleName {
    color: #8df54e;
}

.orderDescriptionBoxTitleDiv .orderTitleName.whiteTitle {
    color: #f0f0f0;
}

.orderDescriptionBoxHeaderDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.orderDescriptionBoxHeader {
    display: flex;
    align-items: center;
    gap: 8px;
}

.orderTitleName {
    text-align: left;
    width: 100%;
    color: #e3e3e3;
    font-family: Montserrat;
    font-size: 13px;
    position: relative;
    top: 10px;
}

.orderProgressDiv {
    width: 10%;
    margin: 1px 2.5%;
    display: block;
    float: right;
}

.orderProgressDivPendingBtn {
    width: 10%;
    margin: 0px;
    display: block;
    float: right;
    background: transparent;
    transition: 300ms;
}

.orderProgressDivPendingBtn:hover {
    scale: 1.2;
}

.descBoxOrderProgressDiv {
    float: right;
    margin: 4px 12px;
}

.CircularProgressbar-text {
    text-anchor: middle;
}

.orderDescBoxRecommendations {
    width: 50%;
    float: left;
    padding-top: 12px;
}

.orderDescBoxRecommendationsLabel {
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
}

.orderDescBoxRecommendationsValue {
    color: #f0f0f0;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
}

.pauseDiv {
    padding-bottom: 12px;
    float: left;
    display: block;
    width: 100%;
    overflow: hidden;
}

.pauseDiv img {
    float: left;
    padding-right: 12px;
}

.delayedText {
    float: left;
    color: #f2a93b;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.9;
    /* 133.333% */
}

.delayedTextBar {
    padding: 8px 0px 0px;
    width: 100%;
    float: left;
    color: #f2a93b;
    font-family: Montserrat;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: 16px;
    /* 133.333% */
}

.hoverBoxParentContainer {
    position: relative;
    display: inline;
    top: -25px;
}

.orderThreeDotMenu {
    padding: 6px 8px;
    display: block;
    float: right;
    background: transparent;
}

.circularBar {
    max-width: 32px;
    margin: 0 8px;
}

.pauseIcon {
    position: relative;
    top: 7px;
}

.pauseIcon.noTop {
    top: 3px !important;
}

.pauseIcon.rotationIcon {
    animation: rotation 6s infinite linear;
}

.recurringIcon {
    z-index: 5;
    transition: 300ms;
}

.recurringIcon:hover {
    scale: 1.2;
}

.pauseIcon.orderPendingIcon {
    scale: 1.3;
    position: relative;
    top: 5px;
}

.scrollableOrdersBar {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
}

.scheduleDescription {
    padding: 8px 12px;
}

.activeHeader {
    color: #ccf54e;
}

.ordersTypeHeaderTwoLineDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    float: left;
    overflow: hidden;
}

.ordersCount {
    /* color: rgba(240, 240, 240, 0.8); */
    padding-top: 4px;
}

.dropdownArrowWhiteSpacer {
    margin: 0px 8px;
    max-width: none;
}

.ordersGreenHeader.subOrderHeaderClass {
    float: left;
    margin-left: 0px;
}

.ordersGreenHeader.subOrderHeaderClass:hover {
    scale: 1.1;
}

.mouse-cursor-pointer {
    cursor: pointer;
}