
:root {
    --webkit-slider-thumb-width: 20px;
    --webkit-slider-thumb-height: 20px;
}
.preview__slider__container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 250px;
    transform: translate(-50%, -50%);
    gap: 20px;
    background: transparent; 
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.right__count__container,
.left__count__container{
  position: absolute;
  display: none;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--white-10, rgba(255, 255, 255, 0.10));
  backdrop-filter: blur(2px);
  &.show__container{
    display: flex;
  }
}
.left__count__container {
    left: 0;
}
.right__count__container {
    right: 0;
}


.buttons__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    gap: 16px;
}

.previous__button,
.play_pouse__buton,
.next__button {
  display: flex;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  gap: 0;
  svg{
    height: 12px;
    width: 12px;
  }
}
.play_pouse__buton{
  svg{
    height: 20px;
    width: 20px;
  }
}