.simulate__details__page {
    height        : 100%;
    width         : 100%;
    display       : flex;
    gap           : 12px;
    padding       : 10px 30px;
    flex-direction: column;
    overflow      : hidden;
}

.simulate__page__header {
    height         : 60px;
    width          : 100%;
    display        : flex;
    align-items    : center;
    justify-content: space-between;

    .simulate__header__title {
        color      : #CCF54E;
        font-family: Montserrat;
        font-size  : 20px;
        font-style : normal;
        font-weight: 300;
        line-height: 21px;
    }

    .simulate__header__deploy__button {
        display        : flex;
        width          : 123px;
        height         : 40px;
        padding        : 6px 12px;
        justify-content: center;
        align-items    : center;
        gap            : 4px;
        background     : transparent;
        border-radius  : 4px;
        border         : 0.5px dashed #FFF;
        opacity        : 0.8;
    }
}

.simulate__page__body {
    height        : calc(100% - 60px);
    width         : 100%;
    display       : flex;
    flex-direction: column;
    gap           : 12px;
    overflow      : hidden;
    padding-bottom: 20px;

    .simulate__body__table__header {
        width          : 100%;
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        padding        : 12px 24px;
        border-radius  : 8px;
        border         : 0.5px solid #F0F0F0;
        opacity        : 80%;
    }

    .simulate__body__table__container {
        height        : calc(100% - 60px);
        width         : 100%;
        display       : flex;
        flex-direction: column;
        gap           : 12px;
        overflow      : auto;

        .simulate__body__table__row {
            width          : 100%;
            display        : flex;
            align-items    : center;
            justify-content: space-between;
            font-size      : 14px;
            font-style     : normal;
            font-weight    : 400;
            line-height    : normal;
            padding        : 12px 24px;
            border-radius  : 4px;
            border         : 1px solid rgba(240, 240, 240, 0.10);
            background     : rgba(0, 0, 0, 0.25);

            &.draft {
                border    : 1px solid rgba(240, 240, 240, 0.10);
                background: rgba(0, 0, 0, 0.25);

                .status__indicator {
                    background: #3468D5;
                }

                .status__badge {
                    color     : rgba(135, 195, 255, 0.98);
                    border    : 0.5px solid #3469D5;
                    background: rgba(52, 105, 213, 0.20);
                }
            }

            &.ready_to_deploy {
                border-radius: 4px;
                border       : 1px solid rgba(240, 240, 240, 0.20);
                background   : rgba(0, 0, 0, 0.25);

                .status__indicator {
                    background: #CCF54E;
                }

                .status__badge {
                    color     : #CCF54E;
                    border    : 0.5px solid #CCF54E;
                    opacity   : 0.9;
                    background: rgba(204, 245, 78, 0.20);
                }
            }

            &.ReadyForSimulation {
                border-radius: 4px;
                border       : 1px solid rgba(240, 240, 240, 0.20);
                background   : rgba(0, 0, 0, 0.25);

                .status__indicator {
                    background: #CCF54E;
                }

                .status__badge {
                    color     : #CCF54E;
                    border    : 0.5px solid #CCF54E;
                    opacity   : 0.9;
                    background: rgba(204, 245, 78, 0.20);
                }
            }

            &.DeploymentInProgress {
                border    : 1px solid rgba(242, 169, 59, 0.40);
                background: rgba(0, 0, 0, 0.25);

                .status__indicator {
                    background: #F2A93B;
                }

                .status__badge {
                    color     : #F2A93B;
                    border    : 0.5px solid #F2A93B;
                    background: rgba(242, 169, 59, 0.20);
                }
            }

            &.deploying {
                border    : 1px solid rgba(242, 169, 59, 0.40);
                background: rgba(0, 0, 0, 0.25);

                .status__indicator {
                    background: #F2A93B;
                }

                .status__badge {
                    color     : #F2A93B;
                    border    : 0.5px solid #F2A93B;
                    background: rgba(242, 169, 59, 0.20);
                }
            }

            &.running {
                color     : #CCF54E !important;
                border    : 1px solid rgba(240, 240, 240, 0.10);
                background: rgba(204, 245, 78, 0.10);

                .status__indicator {
                    background: #CCF54E;
                }

                .status__badge {
                    color     : #CCF54E;
                    border    : 0.5px solid #CCF54E;
                    opacity   : 0.9;
                    background: rgba(204, 245, 78, 0.20);
                }
            }

            &.completed {
                border    : 1px solid rgba(240, 240, 240, 0.10);
                background: rgba(0, 0, 0, 0.25);

                .status__indicator {
                    background: #F0F0F0;
                }

                .status__badge {
                    color     : #F0F0F0;
                    border    : 0.5px solid #F0F0F0;
                    background: rgba(240, 240, 240, 0.20);
                }
            }

            &.failed {
                border    : 1px solid rgba(240, 240, 240, 0.10);
                background: rgba(0, 0, 0, 0.25);

                .status__indicator {
                    background: #F33;
                }

                .status__badge {
                    color     : #F33;
                    border    : 0.5px solid #F33;
                    background: rgba(255, 51, 51, 0.20);
                }
            }

            .status__indicator {
                height: 8px;
                width : 8px;
            }

            .status__badge {
                display        : flex;
                padding        : 4px 12px;
                justify-content: center;
                align-items    : center;
                gap            : 4px;
                border-radius  : 33px;
                text-transform : capitalize;
                font-family    : Montserrat;
                font-size      : 12px;
                font-style     : normal;
                font-weight    : 400;
                line-height    : normal;
            }

            .simulate__body__table__row__button {
                display        : flex;
                background     : transparent;
                justify-content: center;
                align-items    : center;
                padding        : 0px;

                .svg__container {
                    width          : 28px;
                    height         : 16px;
                    display        : flex;
                    justify-content: center;
                    align-items    : center;
                    position       : relative;
                }

                .button__dropdown__container {
                    height    : auto !important;
                    width     : auto !important;
                    min-width : 80px !important;
                    background: #000 !important;
                    padding   : 0 !important;
                    overflow  : hidden !important;

                    .button__dropdown__item:hover {
                        background: rgba(240, 240, 240, 0.2) !important;
                        color     : #fff !important;
                    }
                }
            }
        }
    }


    .spin__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width           : 20px;
        height          : 20px;
        border-radius   : 50%;
        border          : 1px solid #F2A93B;
        animation: deploying_spin 3s linear infinite;
        position: relative;
        &::after {
            content: "";
            height: 10px;
            width: 10px;
            border-radius: 50%;
            background: #D9D9D9;
        }
        &::before{
            content: "";
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background: #F2A93B;
            position: absolute;
            top: -3px;
        }
    }
}

@keyframes deploying_spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}