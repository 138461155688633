.report_section_container{
    right: 0;
    top: 10pt;
    display: flex;
    position: absolute;
    width: 349pt;
    height: 100vh;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    background: rgba(0, 0, 0, 0.50);
    box-shadow: 2pt 0pt 20pt 0pt rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(20pt);
}

.close_report_section{
    display: none;
}

.nav_section{
    position: absolute;
    display: flex;
    top: 15pt;
    right: 349pt;
    width: var(--40, 40pt);
    height: var(--40, 40pt);
    padding: 8pt;
    justify-content: center;
    align-items: center;
    gap: 8pt;
    border-radius: 4pt;
    border-radius: 4px var(--none, 0px) var(--none, 0px) 4px;
    background: rgba(9, 9, 9, 100);
}

.nav_active{
    border: 0.5pt solid #CCF54E;
    background: rgba(204, 245, 78, 0.30);
}

.close_nav{
    right: 1pt
}

.report_setion_title_part{
    display: flex;
    padding: 4pt 4pt 4pt var(--12, 12pt);
    justify-content: space-between;
    align-items: center;
    align-self: stretch;    
    border-bottom: 0.5pt solid #F0F0F0;
    span{
        color: #F0F0F0;
        font-family: Montserrat;
        font-size: 16pt;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.report_selection_part{
    display: flex;
    padding: 8pt var(--12, 12pt) 0pt var(--12, 12pt);
    align-items: center;
    gap: var(--12, 12pt);
    align-self: stretch;
    border-bottom: 0.5pt solid rgba(240, 240, 240, 0.10);
}

.normal_report_selection{
    display: flex;
    width: var(--40, 40pt);
    height: 34pt;
    padding: 8pt var(--12, 12pt);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10pt;
    border-radius: 4pt 4pt var(--none, 0pt) var(--none, 0pt);
}

.selected_report{
    display: flex;
    width: var(--40, 40pt);
    height: 34pt;
    padding: 8pt var(--12, 12pt);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10pt;
    border-radius: 4pt 4pt var(--none, 0pt) var(--none, 0pt);
    border-top: 1pt solid #CCF54E;
    background: rgba(204, 245, 78, 0.10);
}

.selected_report.coverage_report{
    svg {
        path {
            stroke: rgba(204, 245, 78, 1);
        }
    }
}

.selected_report.gs_report{
    svg {
        path {
            fill: #CCF54E;
        }
    }
}