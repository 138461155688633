.error__page__container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    height: 100vh;
    width: 100vw;
    background: #191A1A;
    backdrop-filter: blur(15px);
    position: relative;
    overflow: hidden;

    .error__page__svg__container{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        transform: translate(35%, -35%);
    }
    .error__page__content__container{
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 200px;
        width: 350px;
        text-align: center;
        transform: translateY(70%);
        .error__page__content__title{
            color: #CCF54E;
            font-family: Montserrat;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.675px;
            opacity: 0.6;
        }
        .error__page__content__description{
            color: rgba(240, 240, 240, 0.50);
            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: -0.27px;
        }

        .error__page__back__button{
            display: inline-flex;
            padding: 8px 24px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: var(--small, 4px);
            background: #45570D;
            margin-top: 20px;
            color: #F0F0F0;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            transition: all 0.3s ease;
            &:hover{
                opacity: 0.6;
            }
        }
    }
}