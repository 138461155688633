.create__scenario__container,
.create__additional__object__container {
    display        : flex;
    width          : 350px;
    position       : absolute;
    top            : 20px;
    left           : 20px;
    flex-direction : column;
    align-items    : flex-start;
    border-radius  : 0px 8px;
    border         : 0.5px solid rgba(240, 240, 240, 0.20);
    background     : rgba(15, 15, 15, 0.69);
    box-shadow     : 0px 0px 20px 0px rgba(52, 52, 52, 0.30);
    backdrop-filter: blur(125px);
    z-index: 99;

    .create__additional__object__header,
    .create__additional__object__footer,
    .create__scenario__header,
    .create__scenario__footer {
        display        : flex;
        padding        : 8px 12px;
        justify-content: space-between;
        align-items    : center;
        align-self     : stretch;
    }

    .create__additional__object__header,
    .create__scenario__header {
        border-bottom     : 0.5px solid #F0F0F0;
        border-image      : linear-gradient(to right, rgba(240, 240, 240, 0.20), rgba(240, 240, 240, 0));
        border-image-slice: 1;
    }

    .create__additional__object__footer,
    .create__scenario__footer {
        border-top        : 0.5px solid #F0F0F0;
        border-image      : linear-gradient(to right, rgba(240, 240, 240, 0.20), rgba(240, 240, 240, 0));
        border-image-slice: 1;
    }

    .header__title {
        color         : #CCF54E;
        font-family   : Montserrat;
        font-size     : 16px;
        font-style    : normal;
        font-weight   : 400;
        line-height   : normal;
        letter-spacing: 0.64px;
    }

    .header__button {
        height         : 24px;
        width          : 24px;
        border-radius  : 4px;
        padding        : 0px;
        background     : transparent;
        display        : flex;
        justify-content: center;
        align-items    : center;
        cursor         : pointer;

        &:hover {
            background: #292929;
        }
    }

    .footer__next__button,
    .footer__close__button {
        display        : flex;
        height         : 35px;
        width          : auto;
        padding        : 0px 24px;
        background     : transparent;
        align-items    : center;
        justify-content: center;
        border-radius  : 4px;
        gap: 6px;
        border         : 1px solid rgba(240, 240, 240, 0.20);
        &:disabled{
            opacity: 0.6;
            pointer-events: none;
            cursor: default;
        }
    }

    .footer__next__button {
        background: #45570D;
    }

    .create__additional__object__body,
    .create__scenario__body {
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
        align-self    : stretch;
        flex          : 1 0 0;
        max-height    : 500px;
        overflow-y    : auto;
    }

    .dropdown__container {
        display       : flex;
        flex-direction: column;
        align-items   : flex-start;
        align-self    : stretch;
        background: rgba(255, 255, 255, 0.00);
        border-bottom     : 0.5px solid transparent;
        border-image      : linear-gradient(to right, #6967676a, #3433333a);
        border-image-slice: 1;
        .dropdown__container__header {
            display        : flex;
            padding        : 8px;
            justify-content: space-between;
            align-items    : center;
            align-self     : stretch;
            background: transparent;
            .drop__arrow{
                transition: all 0.3s ease-in-out;
            }

            .dropdown__header__title {
                color      : rgba(240, 240, 240, 0.60);
                text-align : center;
                font-family: Montserrat;
                font-size  : 13px;
                font-style : normal;
                font-weight: 500;
                line-height: normal;
            }
        }

        .dropdown__container__body {
            display       : flex;
            flex-direction: column;
            align-items   : flex-start;
            width: 100%;
            height: 0;
            padding: 0;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
        }

        .drop__down__body__item{
            padding: 0px;
            height: auto;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: transparent;
            padding: 6px 12px;
            &:hover{
                background: rgba(255, 255, 255, 0.03);
            }
            &.active{
                .eye__icon path{
                    stroke: #CCF54E;
                }
            }
        }

        &.active {
            background: rgba(204, 245, 78, 0.05);
            .dropdown__container__body {
                height    : auto;
                padding   : 8px 12px 12px 12px;
                gap       : 12px;
            }

            .dropdown__container__header {
                background: rgba(255, 255, 255, 0.05);
                .dropdown__header__title {
                    color: #F0F0F0;
                }
                .drop__arrow {
                    fill     : #F0F0F0;
                    stroke     : #F0F0F0;
                    stroke-width: 0.5px;
                    transform: rotate(90deg);
                }
            }
        }
        &.disabled{
            opacity: 0.6;
            pointer-events: none;
            cursor: default;
        }

        .orbit__text__input__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px;
            overflow: hidden;
            border: 0.5px solid rgba(240, 240, 240, 0.30);
            border-radius: 4px;
            background: rgb(0, 0, 0);
            &.view__only{
                background: transparent;
                border: none;
                .orbit__text__input{
                    background: transparent !important;
                    border: none !important;
                    cursor: default !important;
                    pointer-events: none !important;
                    text-align: end !important;
                }
                .parameter__section{
                    padding: 0;
                    color: #F0F0F0;
                }
            }
            .parameter__section{
                padding: 4px 8px;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: #8E8E8E
            }
            .orbit__text__input {
                display: flex;
                height: 30px;
                padding: 4px 8px;
                padding-right: 0px;
                justify-content: space-between;
                align-items: center;
                border-radius: 0px;
                border: none;
                border-right: 0.5px solid rgba(240, 240, 240, 0.30);
                background: rgb(0, 0, 0);
                &:disabled{
                    opacity: 0.5;
                    cursor: default;
                    pointer-events: none;
                }
            }
        }
    }
}


.button__dropdown__container{
    height: auto !important;
    width: auto !important;
    min-width: 80px !important;
    background: #000000 !important;
    padding: 0 !important;
    overflow: hidden !important;

    .button__dropdown__item:hover{
        background: rgba(240,240,240,0.1) !important;
        color: #fff !important;
    }
}

.create__additional__object__container{
    display: none;
    left: 390px;
    animation: animate_pop 0.5s ease-in-out;
    &.open{
        display: block;
    }
}
@keyframes animate_pop {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}


.spin__loader{
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid rgba(240,240,240,0.8);
    border-top: 1px solid rgba(240, 240, 240, 0.4);
    animation: loader_spin 1s linear infinite;
}
@keyframes loader_spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}