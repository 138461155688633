.command__center_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px;
    position: absolute;
    gap: 16px;
    z-index: 99;
    top: 0;
}

/* // LEFT HEADER CONTAINER // */
.header_left_container {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #E3E3E3;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;

    .header__dropdown__container{
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 8px;
        border: 1px solid rgba(240, 240, 240, 0.20);
        background: #191A1A;
        width: 220px;
        height: auto;
        top: 15px;
        left: 70px;
        position: absolute;
        .drop__down__icon,
        .svg__container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            padding: 0px;
            background: transparent;
            transform: rotate(0deg);
            &:disabled{
                opacity: 0.6;
                cursor: auto;
                pointer-events: none;
            }
        }
        .drop__down__icon{
            transform: rotate(90deg);
            transition: all 0.3s ease;
        }

        .header__dropdown__container__header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 40px;
            width: 100%;
            padding: 0px 8px;
            box-shadow: 0px 0.5px #33343480;
            .dropdown__header__item{
                display: flex;
                align-items: center;
                justify-content: start;
                text-align: start;
                .dropdown__header__text{
                    width: 110px;
                    color: #E3E3E3;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
        .header__dropdown__container__body{
            display: none;
            flex-direction: column;
            width: 100%;
            padding: 4px;
            height: auto;
            max-height: 125px;
            overflow-y: auto;
            transition: all 0.3s ease;
            &::-webkit-scrollbar{
                display: none;
            }
            &:hover::-webkit-scrollbar{
                display: flex;
            }
            .dropdown__body__item{
                display: flex;
                align-items: center;
                justify-content: start;
                text-align: start;
                padding: 4px 8px 4px 2px;
                border-radius: 4px;
                opacity: 0.6;
                background: transparent;
                height: auto;
                cursor: pointer;
                &:hover{
                    background: rgba(89, 92, 92, 0.4);
                }
                .dropdown__body__text{
                    max-width: 150px;
                    color: #F0F0F0;
                    font-family: Montserrat;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        &.open{
            .drop__down__icon{
                transform: rotate(0deg);
            }
            .header__dropdown__container__body{
                display: flex;
            }
        }
    }

    .left_button {
        display: flex;
        border-radius: 4px;
        border: 1px solid rgba(240, 240, 240, 0.20);
        height: 40px;
        min-width: 40px;
        align-items: center !important;
        justify-content: center;
        gap: 12px;
        flex-shrink: 0;
        border-radius: 4px;
        background: #191A1A;
        cursor: pointer;
        span{
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &.active{
            .globe__icon__badge{
                border: 1px solid rgba(204, 245, 78, 0.20);
                background: #1C2113;
            };
            
        }
        .globe__icon__badge{
            height: 25px;
            width: 25px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background: transparent;
        }
        &:nth-child(2) {
            padding: 12px 14px;
            position: relative;
        }
    };
    .scenario__time__info__badge{
        display: none;
        gap: 12px;
        height: var(--40, 40px);
        padding: 0px var(--16, 16px);
        align-items: center;
        border-radius: var(--4, 4px);
        border: 1px solid rgba(240, 240, 240, 0.20);
        background: rgba(204, 245, 78, 0.10);
        backdrop-filter: blur(10px);
        color: #F0F0F0;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        .time__icon_container{
            display: flex;
            align-items: center;
            gap: 6px;
            span{
                opacity: 0.6;
            }
        }

        &.show__time__info__badge{
            display: flex;
        }
    }
}

.scenario__orbit__info__container,
.tle__info__container {
    width: 0px;
    height: 0px;
    left: 0;
    top: 0;
    display: none;
    position: absolute;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 8px;
    border: 0.5px solid rgba(238, 238, 238, 0.20);
    background: #151616;
    transition: all 0.3s ease;
}
.scenario__orbit__info__container.active{
    display: flex;
    width: 450px;
    height: auto;
    top: 70px;
    left: 70px;
    padding: 12px;
    animation: info__card__animation__active 0.3s ease;
    cursor: default;
    z-index: 10;
}
.tle__info__container.active {
    display: flex;
    width: 550px;
    height: auto;
    top: 40px;
    left: 0;
    padding: 12px;
    animation: info__card__animation__active 0.3s ease;
    z-index: 10;
}
.scenario__orbit__info__header,
.tle__container__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: rgba(240, 240, 240, 0.4);
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .info__title {
        color: #CCF54E;
        font-size: 16px;
        text-align: start;
    }

    .close_button {
        display: flex;
        padding: 0px;
        align-items: center;
        background: transparent;
        .close_icon{
            color: rgba(240, 240, 240, 0.80);
        }
    }
}

.tle__info_badge {
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    padding: 5px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: rgba(240, 240, 240, 0.05);
    color: #F2A93B;
}

.tle__type__badge {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-right: 5px;
    border-radius: 4px;
    background: rgba(204, 245, 78, 0.30);;
    color: rgba(240, 240, 240, 0.80);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.scenario__orbit__info__details__container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    gap: 12px;
    .container__right__section,
    .container__left__section{
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 12px;

        .info__details{
            display: flex;
            flex-direction: column;
            gap: 6px;
            text-align: start;
            span{
                color: #F0F0F0;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            };
            span:nth-child(2){
                font-size: 10px;
                opacity: 0.6;
            }
        }

    }
}

.tle__details__section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    align-self: stretch;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #F0F0F0;
    transition: all 0.3 ease;

    .tle__item {
        position: relative;
        /* Make the element a container for the tooltip */
        cursor: pointer;
        white-space: pre;
    }

    .tle__item:hover {
        scale: 1.05;
        color: #CCF54E;
    }
}

@keyframes info__card__animation__active {
    from {
        scale: 0;
        opacity: 0;
    }

    to {
        scale: 1;
        opacity: 1;
    }
}


/* // LEFT HEADER CONTAINER // */

/* // CENTER HEADER CONTAINER // */
.header_center_container {
    display: flex;
    flex: 1;
    height: 40px;
    overflow-y: visible; /* Not relevant in this context */
}

.header__notification__container {
    display: none;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
    height: 100%;
    padding-bottom: 0px;
    transition: all 0.5s ease;
    overflow: hidden;
    &.active {
        height: 400px;
        overflow-y: auto;
        padding-bottom: 40px;
        -ms-overflow-style: none; /* Microsoft-specific */
        scrollbar-width: none;   /* Modern browsers */
    };
    &.show__notification{
        display: flex;
    }
}


.notification__container{
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
    padding: 10px 16px;
    background: #C91818;
    border-radius: 4px;
}
.notification__icon{
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0px;
    transition: all 0.3s ease;
    &.active{
        transform: rotate(180deg);
    }
}
.notification__count__badge{
    color: #C91818;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    width: 20px;
    border-radius: 100%;
    background: white;
}
.notification__details{
    color: #F0F0F0;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    height: 20px;
    display: flex;
    flex: 1;
    text-align: start;
    flex-direction: column;
    overflow: hidden;
    transition: height 0.3s ease;
    line-height: normal;
    gap: 8px;
    &.active{
        height: 110px;
    };
    button{
        display: flex;
        padding: 8px 12px 8px 11px;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        background: #FFF;
        width: 100px;
        color: #151616;
    }
}
.notification__delete__animation{
    scale: 0 !important;
    opacity: 0.5 !important;
    transition: all 0.3s ease !important;
}

/* // CENTER HEADER CONTAINER // */


/* // RIGHT HEADER CONTAINER // */

.header_right_container {
    display: flex;
    align-items: center;
    gap: 12px;
}

.right__button__container {
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    overflow: hidden;
    &.hide__section{
        display: none;
    }
}

.right__section__button {
    background: transparent;
    padding: 0;
    margin: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    background: #191A1A;
    position: relative;
    &.single__btn{
        border-radius: 8px;
    }
    &.center__btn {
        border-radius: 0px;
    }
    &.right__btn {
        border-radius: 0px 8px 8px 0px;
    }
    &.left__btn{
        border-radius: 8px 0px 0px 8px;
    }
    &.hide__btn{
        display: none;
    }
    &.active{
        border: 0.5px solid rgba(204, 245, 78, 0.50);
        background: #1C2113;
        svg {
            stroke: #CCF54E;
            stroke-width: 0.5px;
        }
    };
    &:hover {
        background: #1C2113;
        svg {
            stroke: #CCF54E;
            stroke-width: 0.5px;
        }
    }
    &.disabled{
        pointer-events: none;
        cursor: default;
        border: 0.5px solid #313131;
        svg {
            opacity: 0.3;
        }
    }
    .pinned{
        position: absolute;
        top: 0px;
        right: 0px;
    }
}



/* // RIGHT HEADER CONTAINER // */