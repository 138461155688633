.activeHeaderBar {
  background: rgba(255, 255, 255, 0.05);
}

.subOrderTitleName {
  text-align: left;
  color: #e3e3e3;
  font-family: Montserrat;
  font-size: 13px;
  float: left;
  display: block;
}

.subOrderTitleName.grey {
  color: #e3e3e3;
  opacity: 0.65;
}

.subOrderTitleName.white {
  color: #f0f0f0;
}

.subOrderTitleName.yellow {
  color: #f2a93b;
}

.orderTitleSubName {
  text-align: left;
  width: 100%;
  color: rgba(240, 240, 240, 0.65);
  font-family: Montserrat;
  font-size: 12px;
}

.threeDotMenuParentDiv {
  position: relative;
  display: inline;
  top: -30px;
}

.hoverBoxNotification {
  height: auto;
  width: auto;
  padding: 12px;
  border-radius: 8px;
  border: 0.5px rgba(240, 240, 240, 0.2);
  background: rgba(15, 15, 15, 0.69);
  backdrop-filter: blur(5px);
  position: fixed;
  left: 318px;
  /* left: 280px; */
  min-width: 260px;
}

.hoverBoxNotificationHeaderDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.hoverBoxNotificationHeaderText {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  min-width: 200px;
  text-align: left;
}

.hoverBoxNotificationHeaderNum {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.hoverBoxNotificationBodyDivHeader {
  font-size: 11px;
  color: #f0f0f0;
  text-align: left;
  padding-top: 8px;
}

.hoverBoxNotificationBodyDiv {
  align-self: stretch;
  color: #f0f0f0ad;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
  margin-top: 8px;
}

.datepicker-toggle {
  display: inline-block;
  position: relative;
  width: 18px;
  height: 19px;
  margin-left: 12px;
}
.datepicker-toggle-button {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.datepicker-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.orderCreationModal {
  position: absolute;
  left: 20px;
  top: 85px;
  width: 320px;
  border-radius: 0px 8px;
  border: 0.5px solid rgba(240, 240, 240, 0.2);
  background: rgba(15, 15, 15, 0.69);
  box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.3);
  backdrop-filter: blur(125px);
}

.orderCreationModal.policyCreationModal {
  left: 360px;
}

.orderCreationModalHeader {
  width: 100%;
  padding: 4px 12px;
  display: block;
  height: 40px;
}

.orderCreationModalHeaderText {
  color: #ccf54e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.64px;
  float: left;
}

.orderCreationModalHeader button {
  float: right;
  background: transparent;
  height: 100%;
  transition: 300ms;
}

.orderCreationModalHeader button:hover {
  scale: 1.2;
}

.orderTypeMainBars {
  padding: 12px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 0.1px;
  border-image: linear-gradient(15deg, #a0a0a0, #000) 1;
}

.subOrderTypeMainBars {
  padding: 12px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 0.1px;
  border-image: linear-gradient(15deg, #a0a0a0, #000) 1;
}

.orderCreationModalFooter {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.modalFooterCloseBtn {
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 0.5px solid rgba(240, 240, 240, 0.5);
  background: rgba(240, 240, 240, 0);
  transition: 300ms;
}

.modalFooterCloseBtn:hover {
  color: rgba(240, 240, 240, 0.8);
  background: rgba(240, 240, 240, 0.3);
}

.modalFooterApplyBtn {
  color: #f0f0f0;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 0.5px solid rgba(240, 240, 240, 0.5);
  background: #20563d;
  transition: 300ms;
}

.modalFooterApplyBtn:hover {
  background: #30805b;
  color: #fff;
}

.modalSectionHeader {
  height: 40px;
}

.modalSectionDescription {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 12px;
  padding: 12px;
  text-align: center;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 0.1px;
  border-image: linear-gradient(15deg, #a0a0a0, #000) 1;
}

.UcpModalSection {
  padding: 12px;
  width: 100%;
}

.modalInputTitleWhite {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.modalInputFieldFullLen {
  width: 100%;
  border-radius: 4px;
  border: 0.5px solid rgba(240, 240, 240, 0.3);
  background: rgba(10, 10, 10, 0.65);
  margin: 8px 0px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  padding: 4px 12px;
}

.modalInputFieldFullLen:placeholder-shown {
  color: #8e8e8e;
}

.modalInputFieldRightFloat {
  width: 200px;
  float: right;
  margin: 0px;
}

.modalInputCircularButton {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: transparent;
  border: 1px solid #f0f0f0;
  cursor: pointer;
}

.modalInputCircularButton.active::after {
  background: #f0f0f0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  content: '';
  display: block;
  margin-left: -4px;
}

.modalInputCircularButton.greenCurrent {
  border: 2px solid #ccf54e;
}

.modalInputCircularButton.greenActive {
  border: 2px solid #ccf54e;
  background: #ccf54e;
}

.modalInputRoundedBoxButton {
  border-radius: 4px;
  width: 16px;
  height: 16px;
  background: transparent;
  border: 2px solid #f0f0f0;
  cursor: pointer;
}

.modalInputRoundedBoxButton.active {
  background: #f0f0f0;
  background-size: 90%;
}

.modalDivLine {
  padding: 12px 0px 0px;
  width: 100%;
  overflow: hidden;
}

.modalInputBox {
  padding: 4px;
  float: left;
  display: block;
  margin-right: 12px;
}

.modalInputBox .modalInputBoxText {
  float: right;
  color: #eee;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 175% */
  padding-left: 8px;
}

.modalGreenTransparentBtn {
  border-radius: 4px;
  border: 0.5px solid #ccf54e;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  float: right;
  background-color: transparent;
  color: rgba(240, 240, 240, 0.8);
  font-family: Montserrat;
  font-size: 12px;
  transition: 300ms;
}

.modalGreenTransparentBtn:hover {
  background-color: rgba(240, 240, 240, 0.1);
  color: rgba(240, 240, 240, 1);
}

.modalGreenTransparentBtn.active {
  background-color: #ccf54e;
  color: #000;
  font-weight: 600;
}

.modalGreenTransparentBtn.active:hover {
  background-color: #ccf54e;
  color: #000;
}

.modalSelectBtn {
  width: 100%;
  background: rgba(10, 10, 10, 0.65);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modalSelectBtn:hover {
  cursor: pointer;
}

.modalSelectBtn .modalSelectOptionBtn {
  background: rgba(15, 15, 15, 0.3);
  background-color: rgba(15, 15, 15, 0.3);
  box-shadow: 0px 0px 20px 0px rgba(52, 52, 52, 0.3);
  backdrop-filter: blur(125px);
  color: #000;
}

.ucpModalDropdownDiv {
  margin: 8px 0px;
}

.flexCenterDiv {
  display: flex;
  justify-content: center;
}

.ucpCircularButtonBox {
  margin: 0px 12px;
}

.ucpModalTable {
  display: inline-flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  border: 0.5px solid rgba(240, 240, 240, 0.3);
  background: rgba(10, 10, 10, 0.65);
  backdrop-filter: blur(125px);
  position: absolute;
  top: 400px;
  left: 380px;
  width: 890px;
}

.ucpModalTable .orderCreationModalHeaderText {
  line-height: 1;
}

.tableModalTable {
  width: 100%;
}

.ucpModalTableHeaderRow {
  display: flex;
  margin: 6px 0px;
  align-items: center;
  padding: 6px 0px;
  align-self: stretch;
  width: 100%;
}

.ucpModalTableHeaderRow.ucpModalTableHeaderRowActive {
  border-radius: 4px;
  border: 0.5px solid #ccf54e;
  background: rgba(204, 245, 78, 0.1);
}

.ucpModalTableHeaderRow td {
  width: 100%;
  text-align: center;
}

.ucpModalTableHeaderRow td:has(.ucpModalItemActivateBtn) {
  width: 50%;
}

.ucpModalTableHeaderRow td:has(.ucpModalItemViewBtn) {
  width: 50%;
}

.ucpModalTableHeaderRow td:has(.ucpHeaderNoHeaderDiv) {
  width: 50%;
}

.ucpModalTableHeaderElement {
  color: rgba(240, 240, 240, 0.65);
  /* color: #f0f0f0; */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
}

.ucpModalTableHeaderElement.ucpModalTableBodyElement {
  color: rgba(255, 255, 255, 0.7);
}

.ucpModalHeaderBtn {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1;
}

.ucpModalHeaderBtn:hover {
  scale: 1.1 !important;
}

.ucpModalHeaderBtnGreen {
  color: #ccf54e;
}

.ucpModalItemAcceptBtn {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 30px;
  padding: 4px 16px;
  border-radius: 4px;
  border: 0.5px solid #f0f0f0;
  background: transparent;
}

.ucpModalItemAcceptBtn:hover {
  background-color: rgba(240, 240, 240, 0.2);
}

.ucpModalItemAcceptBtnImg {
  padding-right: 6px;
}

.ucpModalItemActivateBtn {
  border-radius: 4px;
  border: 1px solid #eeeeee;
  width: 15px;
  height: 15px;
  background: transparent;
}

.ucpModalItemViewBtn {
  background: transparent;
}

.ucpComparisonModal {
  position: absolute;
  top: 50px;
  right: 300px;
  width: 240px;
  border-radius: 8px;
  border: 0.5px solid rgba(240, 240, 240, 0.3);
  background: rgba(10, 10, 10, 0.65);
  backdrop-filter: blur(125px);
}

.ucpComparisonHalfDiv {
  display: block;
  width: 100%;
}

.ucpComparisonHalfDivTitle {
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  padding: 12px 0px;
}

.ucpComparisonImageDiv {
  margin: 0px 6px 8px;
  width: calc(100% - 12px);
  border-radius: 9px;
  border: 1px solid rgba(240, 240, 240, 0.3);
  background: linear-gradient(307deg, #131715 29.36%, rgba(19, 23, 21, 0) 79.21%), url("../ImgAssets/CompareSampleImage.png"),
    lightgray -3517.612px -1536.399px / 1855.645% 1049.495% no-repeat;
  background-size: cover;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
  height: 220px;
  position: relative;
}

.ucpComparisonValuesDiv {
  position: absolute;
  bottom: 0px;
  margin: 0 auto;
  text-align: center;
  height: auto;
  width: 100%;
}

.ucpComparisonTitleDiv {
  width: 100%;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  text-decoration: underline;
  padding-bottom: 8px;
  text-underline-offset: 3px;
  text-decoration-thickness: 1.5px;
}

.ucpComparisonTitleDiv.orange {
  text-decoration-color: #f2a93b;
}

.ucpComparisonTitleDiv.purple {
  text-decoration-color: #903aff;
}

.ucpComparisonProgressBar {
  height: 34px;
  overflow: hidden;
  width: 32px;
  margin: auto;
  margin-bottom: 6px;
}

.ucpComparisonValueDiv {
  width: 50%;
  float: left;
  display: block;
}

.modalInputNumField {
  border-radius: 4px;
  border: 0.5px solid rgba(240, 240, 240, 0.3);
  background: rgba(10, 10, 10, 0.65);
  width: 84px;
  min-width: 30px;
  padding: 8px 12px;
  height: 31px;
  margin: 2px;
}

.modalInputNumField:placeholder-shown {
  color: rgba(240, 240, 240, 0.4);
  font-family: Montserrat;
  font-size: 12px;
  text-align: center;
}

.modalInputFieldText {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  padding: var(--py-0, 0px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.modalInputFieldAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.modalScrollableSection {
  max-height: calc(100vh - 55vh);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: auto;
}

.modalScrollableSection::-webkit-scrollbar {
  width: 3px;
}

.modalScrollableSection::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.modalScrollableSection::-webkit-scrollbar-thumb {
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modalInputFieldText .modalInputBoxText {
  margin-right: 12px;
}

.orderCreationModalHeader .noButtonHeight {
  height: auto !important;
}

.singleTickIcon {
  position: relative;
  right: 13px;
  top: -2px;
  cursor: pointer;
  z-index: -1;
}

.singleTickIcon .modalTableTickIcon {
  right: -21px;
  top: -10px;
}

.featuresList {
  display: inline-flex;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  border: 0.5px solid rgba(240, 240, 240, 0.2);
  background: rgba(15, 15, 15, 0.5);
  backdrop-filter: blur(10px);
  position: absolute;
  top: 140px;
  right: 80px;
}

.layersFeaturesList {
  bottom: 80px;
  right: 90px;
  top: auto;
}

.featuresList .modalInputBox .modalInputBoxText {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
}

.hideIcon {
  opacity: 0;
}

.transparentButton {
  background: transparent;
  cursor: pointer;
  display: block;
  width: 100%;
}

.ordersBarFeaturesListOne {
  left: 340px;
  width: 160px;
  top: 90px;
}

.ordersBarFeaturesListTwo {
  left: 510px;
  width: max-content;
  top: 90px;
}

.ordersBarFeaturesListThree {
  left: 680px;
  width: max-content;
  top: 90px;
}

.ordersBarFeaturesListFour {
  left: 830px;
  width: max-content;
  top: 90px;
}

.floatLeft {
  float: left !important;
}

.featuresListDropDown {
  float: right;
  padding: 0px;
  position: relative;
  right: 0px;
  top: -3px;
}

.fullWidth {
  width: 100%;
}

.active-img {
  filter: invert(0%) sepia(32%) saturate(4262%) hue-rotate(24deg) brightness(119%) contrast(92%);
}

.activeText {
  color: #ccf54e !important;
}

.ordersGreenHeaderBar button.filterButtonOn {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  border-radius: 4px;
  background: #19191a;
  margin-left: 12px;
}

.threeDotOptionsMenu {
  position: fixed;
  left: 318px;
  width: max-content;
  max-width: 160px;
  padding: 8px;
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(240, 240, 240, 0.20);
  background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(20px);
  overflow: hidden;
  text-align: left;
  
  .modalInputBox {
    padding: 2px 4px;
    .modalInputBoxText {
      padding-left: 0;
    }
  }
}

.threeDotOptionsMenu button {
  background: transparent;
  transition: 300ms;
  padding: 4px 10px;
  margin-right: 0;
}

.threeDotOptionsMenu button:hover {
  scale: 1.1;
}

.threeDotOptionsMenu button div {
  text-align: left;
  float: left !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.noTopPadding {
  padding-top: 0px !important;
}

.dropdownIconWhite {
  background: transparent;
  float: left;
  margin-right: 8px;
}

.dropdownIcon {
  transition: 300ms;
}

.dropdownIcon.open:hover {
  transform: rotate(-90deg);
}

.dropdownIcon.close:hover {
  transform: rotate(90deg);
}

.sectionHeaderText {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  padding-top: 3px;
}

.bottomBorder {
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 0.1px;
  border-image: linear-gradient(15deg, #444, transparent) 1;
}

.topBorder {
  border-style: solid;
  border-width: 0px;
  border-top-width: 0.1px;
  border-image: linear-gradient(15deg, #444, transparent) 1;
}

.sectionDivLine {
  padding: 12px 0px;
  width: 100%;
  overflow: hidden;
}

.activeSectionModal {
  background: rgba(204, 245, 78, 0.03);
}

.inActiveSectionModal {
  opacity: 0.6;
  pointer-events: none;
}

.orderCreationModal .minimizeIcon {
  margin-right: 6px;
}

.disabledBtn {
  pointer-events: none;
  opacity: 0.6;
}

.addObjectsBtn {
  width: 100%;
  height: 32px;
  padding: 4px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 0.5px dashed rgba(240, 240, 240, 0.8);
  background: rgba(255, 255, 255, 0.1);
  margin: 8px 0px 0px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.modalInputTitleWhite.subHeader {
  font-size: 12px;
}

.subHeader.greenSubHeader {
  color: var(--Active-Green, #ccf54e);
  font-family: Montserrat;
  font-size: 14px;
}

.modalInputBoxText.noPadding {
  position: relative;
  padding-left: 0px;
}

.lowerOpacity {
  opacity: 0.7;
}

.addObjectsBtn.unAddedObjectsBtn {
  border-radius: 3px;
  border: 0.5px dashed rgba(240, 240, 240, 0.5);
  background: rgba(204, 245, 78, 0.07);
  text-align: center;
  color: #ccf54e;
}

.orangeNotificationDiv {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: rgba(242, 169, 59, 0.23);
  color: #f2a93b;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.blueNotificationDiv {
  color: #eee;
  background: rgba(10, 70, 103, 0.4);
  margin-top: 12px;
  border-radius: 0;
}

.errorMessageDiv {
  margin-bottom: 12px;
  border-radius: 0;
}

.brightGreenTickIcon {
  float: right;
  margin-right: 12px;
}

.Dropdown-control.reactDropdownClass {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(240, 240, 240, 0.3);
  border-image: initial;
  background: rgba(10, 10, 10, 0.65);
  float: right;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.Dropdown-control.reactDropdownClass.halfWidth {
  width: 150px;
  font-size: 10px;
}

.orderDropdownFields {
  height: 31px;
}

.orderDropdownClass {
  position: absolute !important;
}

.orderDropdownMenuClass {
  background: rgba(10, 10, 10, 1) !important;
}

.reactDropdownRootClass {
  width: 100%;
  position: fixed;
}

.reactDropdownRootClass.halfWidth {
  width: 150px;
  float: right;
}

.Dropdown-menu.reactDropdownMenuClass {
  width: 100%;
  padding: 8px 12px;
  border-radius: 4px;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgba(240, 240, 240, 0.3);
  border-image: initial;
  background: rgba(10, 10, 10, 0.65);
  float: right;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.Dropdown-menu.reactDropdownMenuClass.halfWidth {
  width: 150px;
  font-size: 10px;
}

.Dropdown-menu.reactDropdownMenuClass .Dropdown-option {
  color: #f0f0f0;
  text-align: left;
  padding: 6px 0px;
  transition: 300ms;
}

.Dropdown-menu.reactDropdownMenuClass .Dropdown-option:hover {
  background-color: transparent;
  scale: 1.05;
}

.Dropdown-menu.reactDropdownMenuClass .Dropdown-option.is-selected {
  background-color: transparent;
  color: #f0f0f0;
  text-align: left;
}

.Dropdown-arrow.reactDropdownArrowClassName {
  top: 12px;
}

.strategiesBar {
  display: inline-flex;
  height: calc(100% - 50px);
  width: 296px;
  padding: var(--object-selected, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  border-radius: var(--object-selected, 0px);
  border: 0.5px solid rgba(240, 240, 240, 0.2);
  background: #0f0f0f;
  box-shadow: 0px 0px 20px 0px rgba(43, 43, 43, 0.3);
  -webkit-backdrop-filter: blur(125px);
  backdrop-filter: blur(125px);
  position: absolute;
  right: 0;
  top: 50px;
}

.strategiesScrollableDiv {
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
}

.compareBtnStrategies {
  float: right;
  background: transparent;
  padding: 4px;
  align-items: center;
  border-radius: 4px;
  border: 0.5px solid #ccf54e;
  color: #ccf54e;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 12px;
  position: relative;
  top: -4px;
  transition: 300ms;
}

.compareBtnStrategies:hover {
  scale: 1.1;
}

.strategiesInputBoxText {
  font-size: 14px !important;
  padding-left: 12px;
  line-height: 1.1;
}

.strategyBox {
  padding: 6px 12px;
  width: 100%;
}

.strategyTitleBox {
  display: flex;
}

.strategyDataBox {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 8px;
  border: 0.5px solid #827aa9;
  background: #0f0f0f;
  margin: 12px 0px;
}

.strategyDataBox.highlighted {
  border-radius: 8px;
  border: 0.5px solid #ccf54e;
  background: rgba(204, 245, 78, 0.1);
}

.strategyDataBoxLine {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.strategyDataBoxLabel {
  width: 148px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.strategyDataBoxValue {
  width: 64px;
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.orderTitleMainDiv {
  max-width: 285px;
}

.expandedSubOrderTable {
  width: calc(100% - 24px);
  margin: 8px 12px 12px;
}

.expandedSubOrderTableHeaderRow {
  border-radius: 8px;
  border: 0.5px solid var(--Active-Icons-Colour, rgba(240, 240, 240, 0.6));
}

.expandedSubOrderTableHeaderRow th {
  padding: 12px;
  color: var(--Active-Icons-Colour, rgba(240, 240, 240, 0.6));
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;
  text-align: center;
}

.expandedSubOrderTableSpikeItemRow {
  width: 100%;
  height: 60px;
}

.spikeItemText {
  color: #f0f0f0;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.spikeItemActionBtn {
  background-color: transparent;
  transition: 300ms;
}

.spikeItemActionBtn:hover {
  scale: 1.2;
}

.spikeItemActionBtn img {
  position: relative;
  left: 25px;
  top: 0px;
}

.bottomBarTime {
  color: #f0f0f0;
  font-size: 12px;
  justify-items: end;

  .display-time{
    margin: 4px 16px
  }
}

.bottomBarOrderAnalytics {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 45%;
  bottom: 10px;
  background: transparent;
  border-radius: 4px;
}

.bottomBarOrderAnalyticsComponent {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  background-color: rgba(240, 240, 240, 0.1);
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 300ms;
}

.bottomBarOrderAnalyticsComponent:hover {
  scale: 1.1;
}

.bottomBarOrderAnalyticsComponent.active img {
  filter: invert(100%) sepia(100%) saturate(21%) hue-rotate(2deg) brightness(101%) contrast(107%);
  fill: #000;
}

.bottomBarOrderAnalyticsComponent.active {
  background-color: var(--Active-Green, #ccf54e);
  color: #000;
}

.warningBarBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #0a0a0a;
  width: 100%;
  margin-top: 12px;
  padding: 0px 8px 16px;
}

.warningBarBoxHeader {
  color: #a0bfff;
  font-family: Montserrat;
  font-size: 11px;
  font-style: italic;
  font-weight: 600;
  line-height: 16px; /* 145.455% */
  padding: 12px 0px;
}

.warningProgressBarDiv {
  display: block;
  width: 13px;
  padding: 4px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  float: left;
  margin-left: 8px;
  margin-right: 8px;
}

.warningProgressBarDiv img {
  position: relative;
  width: 15px;
}

/* .warningProgressBarDiv img.first {
} */

.porgressDotIcon.red {
  filter: invert(43%) sepia(18%) saturate(2134%) hue-rotate(314deg) brightness(106%) contrast(104%);
}

.progressVerticalBar {
  border-left: 1.5px solid white;
  height: 72px;
  position: relative;
  left: 6px;
  top: 1px;
}

.progressVerticalBar.red {
  border-left: 1.5px solid #ff4e4e;
}

.warningBarBoxContent {
  float: right;
  padding: 0px 8px;
  width: 240px;
}

.warningBarBoxContentItemText {
  color: rgba(240, 240, 240, 0.8);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.warningBarBoxContentItemText.warning {
  color: #ff4e4e;
}

.warningBarBoxContents {
  display: flex;
  flex-direction: row;
}

.warningBarBoxContentItem {
  height: 90px;
}

.warningBarBoxContentItem.lastItem {
  height: auto;
}

.orderRescheduledBox {
  margin: 12px 0px;
  width: 100%;
  overflow: hidden;
}

.orderRescheduledBoxHeader {
  color: var(--Active-Green, #ccf54e);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  float: left;
}

.orderRescheduledDetailsBtn {
  float: right;
  background: transparent;
  color: var(--Active-Green, #ccf54e);
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 300ms;
  width: 60px;
  height: 24px;
  border-radius: 4px;
}

.orderRescheduledDetailsBtn:hover {
  scale: 1.2;
}

.orderRescheduledDetailsBtn.clicked {
  color: #000;
  background: #ccf54e;
}

.orderRescheduledDetailsBtn.clicked:hover {
  scale: 1;
}

.orderRescheduledBoxSubHeader {
  color: #e3e3e3;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  opacity: 0.65;
  width: 100%;
  padding: 6px 0px;
}

.spikeWarningDetailsBox {
  display: inline-flex;
  padding: 8px 12px 24px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 8px 8px 0px 0px;
  background: rgba(12, 34, 54, 0.9);
  backdrop-filter: blur(40px);
  position: absolute;
  left: 320px;
  bottom: 60px;
  width: 382px;
}

.spikeWarningDetailsBoxHeader {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: uppercase;
}

.spikeWarningDetailsBoxSubHeader {
  color: #e3e3e3;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  opacity: 0.65;
}

.spikeWarningdetailsFailMessage {
  color: #f2a93b;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  float: left;
}

.spikeWarningdetailsFailTime {
  color: rgba(240, 240, 240, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  float: right;
}

.spikeWarningDetailsTypeBox {
  width: 100%;
  border-left: 1.5px solid #c9eeff;
  background: rgba(201, 238, 255, 0.1);
  padding: 8px 12px;
}

.spikeWarningDetailsTypeBoxHeader {
  color: #f0f0f0;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 6px;
}

.spikeWarningDetailsTypeBoxSubHeader {
  color: rgba(240, 240, 240, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
}

.spikeWarningDetailsSpikeCondBox {
  display: block;
  width: 100%;
  padding: 4px 8px;
}

.spikeWarningDetailsSpikeCondBox.highlighted {
  border-radius: 4px;
  background: rgba(240, 240, 240, 0.05);
}

.spikeWarningDetailsSpikeCondTitle {
  color: rgba(240, 240, 240, 0.5);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  float: left;
}

.spikeWarningDetailsSpikeCond {
  color: rgba(204, 245, 78, 0.8);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  float: right;
}

.policyItems {
  display: flex;
  padding: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: rgba(255, 255, 255, 0);
  color: #e3e3e3;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.threeDotMenuView {
  position: relative;
}

.blueFeaturesBar {
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: rgba(10, 70, 103, 0.4);
  color: #eee;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.7;
}

.lowTopPadding {
  padding: 4px 12px;
}

.Select__control {
  border-color: 0.5px solid rgba(240, 240, 240, 0.3) !important;
}

.Select__menu {
  position: sticky !important;
}

.Select__menu-list {
  background: transparent !important;
  background-color: transparent !important;
  border: 0px !important;
}

.Select__option {
  background: #000 !important;
  border: 0px !important;
}

.bottomBarBlackBarComponent {
  width: 100%;
  height: 55px;
  /* border-top: 0.8px solid #36f097; */
  backdrop-filter: blur(46.5px);
  position: fixed;
  bottom: 0;
  left: 0;
  /* background: linear-gradient(90deg, #0E0E0E -3.2%, rgba(0, 0, 0, 0.57) 143.86%); */
  background: #0e0e0e;
  z-index: 2;
}

/* FeaturesBar */

.ucpFeaturesBar {
  position: absolute;
  top: 80px;
  right: 80px;
  height: 54px;
  width: 260px;
  border-radius: 8px;
  border-bottom: 1px solid #f0f0f0;
  background: #1f1f1f;
  box-shadow: 0px 0px 40px 0px rgba(131, 131, 131, 0.3);
  padding: 4px 12px;
  scale: 0.8;
}

.layersFeatureBar {
  bottom: 80px;
  right: 20px;
  top: auto;
}

.scheduleBar {
  top: 74px;
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 8px 0px 0px 8px;
  border-bottom: none;
  background: rgba(9, 9, 9, 0.40);
  box-shadow: 2px 0px 20px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
  padding: 8px; 
}

.scheduleBarOpen {
  right: 692px;
}

.scheduleBarClose {
  right: -4px;
}

.ucpFeaturesBarBtn {
  margin: 0px 8px;
  padding: 10px;
  background: transparent;
}

.ucpFeaturesBarBtn:hover {
  scale: 1.2;
}

.active-action {
  scale: none;
  border-radius: 4px;
  background: rgba(204, 245, 78, 0.20);
}

.uploadIcon {
  margin: 0;
}

.ucpFeaturesIcon {
  width: 40px !important;
}

.ucpAdditionalFeaturesBar {
  top: 80px;
  position: absolute;
  right: 150px;
  float: left;
}

.ucpFeaturesBar.leftOne {
  position: relative;
  right: 12px;
  top: 0px;
  float: left;
  margin-left: 12px;
  display: inline-flex;
  padding: 8px 12px;
  align-items: center;
  flex-shrink: 0;
  color: #ccf54e;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ucpAdditionalFeaturesBar .featuresList {
  top: 60px;
}

.ucpAdditionalFeaturesBar .featuresList.itemOne {
  right: 5px;
}

.ucpAdditionalFeaturesBar .featuresList.itemTwo {
  right: 130px;
}

.ucpFeaturesBar.leftOne img {
  padding-left: 12px;
  float: right;
}

.ucpFeaturesBar.leftOne .noPadding {
  padding-left: 0px !important;
}

/* End FeaturesBar */