.ground__station__contact__container{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: start;
    justify-content: space-between;
}

.ground__container__left__container__wrapper{
    width: calc(98vw - 22vw);
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.ground__container__left__container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .ground__contact__timeframe__section__container{
        display: flex;
        width: 100%;
        font-size: 12px;
        position: relative;
        overflow: visible;
        transition: all 0.5s ease;
    };
    .ground__contact__details__title__container{
        width: 180px;
        display: flex;
        align-items: end;
        height: 50px;
        .ground__contact__details{
            width: 165px;
            height: 100%;
            display: flex;
            align-items: center;
            background: #191A1A;
            position: relative;
            z-index: 99;
            span{
                width: 95%;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                overflow: hidden;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .ground__contact__details.border__right::after{
            content: '';
            position: absolute;
            height: 30px;
            width: 1px;
            background: rgba(240, 240, 240, 0.40);
            right: 0;
        }
    };
    .ground__contact__details__container{
        width: 100%;
        height: calc(100vh - 230px);
        overflow-y: auto;
        margin-top: 2px;
        padding: 10px 0px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        scrollbar-width: none;

        .ground__contact__task__details__container{
            display: flex;
            height: 50px;
            align-items: center;
            .task__details__timeframe__container{
                display: flex;
                position: relative;
                height: 100%;
                overflow-x: hidden;
                margin-top: 15px;
            };

            .ground__task__details__card{
                display: flex;
                height: 35px;
                position: absolute;
                transform: translateX(0);
                transition: all 0.3s ease;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                .event__indicator{
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    display: flex;
                    width: 20px;
                    height: 20px;
                    justify-content: center;
                    align-items: center;
                    background: black;
                    border-radius: 100%;
                }
                .ground__task__card__container{
                    display: flex;
                    width: 100%;
                    overflow: hidden;
                    align-items: center;
                    justify-content: center;
                    padding: 8px 8px;
                    .task_card_svg_container{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        width: 20px;
                    }
                    .task__title{
                        overflow: hidden;
                        color: #B6B6B6;
                        text-overflow: ellipsis;
                        font-family: Montserrat;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        display: none;
                        &.show{
                            display: block;
                        }
                    }
                }
                &.past__event{
                    background: rgba(240, 240, 240, 0.20);
                    box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.10);
                };
                &.ongoing__event{
                    border: 0.5px solid #CCF54E;
                    background: rgba(204, 245, 78, 0.10);
                    box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.10);
                };
                &.upcomming__event{
                    border: 0.5px solid rgba(240, 240, 240, 0.50);
                    background: rgba(240, 240, 240, 0.10);
                    box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.10);
                }
            }

        }
    };
}

.ground__container__header__container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ground__container__header__left__container{
        display: flex;
        align-items: center;
        gap: 12px;
        .button{
            display: flex;
            padding: var(--8, 8px) 12px;
            align-items: center;
            min-width: 100px;
            justify-content: center;
            border-radius: var(--4, 4px);
            background: rgba(240, 240, 240, 0.05);
            color: #F0F0F0;
            font-family: Montserrat;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.8;
            transition: all 0.1s ease;
            &:hover{
                border: 0.5px solid rgba(204, 245, 78, 0.60);
            }
            &.active{
                border: 0.5px solid rgba(204, 245, 78, 0.60);
                background: rgba(204, 245, 78, 0.10);
                color: #CCF54E;
            }
            &:disabled{
                opacity: 0.5;
                cursor: default;
                pointer-events: none;
            }
        };
    };
    .ground__container__header__right__container{
        display: flex;
        align-items: center;
        gap: 12px;
        position: relative;
        max-width: 450px;
        justify-content: end;
        .next__contact__details{
            display: flex;
            align-items: center;
            width: auto;
            max-width: 70%;
            gap: 6px;
            span{
                color: #F0F0F0;
                font-family: Montserrat;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                &:nth-child(2){
                    opacity: 0.6;
                    max-width: 130px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
        .button{
            display: flex;
            align-items: center;
            padding-bottom: 3px;
            justify-content: center;
            border-radius: var(--4, 4px);
            opacity: 0.6;
            background: transparent;
            transition: all 0.1s ease;
            &.active{
                border-radius: var(--4, 4px);
                border: 0.5px solid #CCF54E;
                opacity: 1;
                svg{
                    stroke: #CCF54E;
                }
            };
            &:disabled{
                opacity: 0.5;
                cursor: default;
                pointer-events: none;
            }
        };
        .input__container{
            display: flex;
            align-items: center;
            height: 100%;
            flex: 1;
            input{
                width: 100%;
                height: 100%;
                border: none;
                outline: none;
                background: transparent;
                font-size: 12px;
                &::placeholder{
                    color: rgba(255, 255, 255, 0.5);
                }
                &:focus{
                    border: 0;
                    outline: none;
                }
            }
        }
    }
}


.ground__container__right__container{
    width: 21vw;
    height: calc(100vh - 130px);
    background: #191A1A;
    z-index: 999;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 5px 0px 5px 12px;
    border-left: 1px solid rgba(240, 240, 240, 0.20);

    .contact__details__header{
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        // span{
        //     color: #F0F0F0;
        //     font-family: Montserrat;
        //     font-style: normal;
        //     font-weight: 400;
        //     line-height: normal;
        // };
        // span:nth-child(1){
        //     font-size: 14px;
        // };
        // span:nth-child(2){
        //     font-size: 12px;
        //     opacity: 0.6;
        // };
        

        .contact__details__header__sub__title,
        .contact__details__header__title{
            color: #F0F0F0;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: auto;
            max-width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .contact__details__header__title{
            font-size: 14px;
        };
        .contact__details__header__sub__title{
            font-size: 12px;
            opacity: 0.6;
        }
    }
    .contact__status__badge{
        display: flex;
        padding: var(--8, 8px) 12px;
        justify-content: space-between;
        align-items: center;
        color: #F0F0F0;
        border-radius: var(--4, 4px);
        font-family: Montserrat;
        background: rgba(240, 240, 240, 0.05);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: 100%;
        .badge__title{
            max-width: 70%;
        }
        .time__container{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 12px;
            width: 80px;
            border-radius: var(--4, 4px);
            background: rgba(240, 240, 240, 0.10);
            box-shadow: 0px 0px 10px 0px rgba(10, 10, 10, 0.10);
        }
        &.ongoing{
            background: rgba(204, 245, 78, 0.10);
            color: #CCF54E;
            .time__container{
                background: rgba(204, 245, 78, 0.10);
                border: 0.5px  solid #CCF54E;
            }
        }
    }

    .contact__visualization__miniview{
        width: 100%;
        height: 200px;
        border-radius: var(--4, 4px);
        border: 0.5px solid rgba(240, 240, 240, 0.40);
        // background: url('/public/operate.png');
        background-repeat: no-repeat;
        background-size: contain;
        position: relative
    };
    .ground__contact__details{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        background: #191A1A;
        z-index: 999;
        .band{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            color: #F0F0F0;
            display: flex;
            align-items: center;
            gap: 2px;
            span{
                font-size: 10px !important;
                opacity: 0.6;
            }
        }
        span{
            color: #F0F0F0;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        };
        span:nth-child(1){
            font-size: 12px;
        };
        span:nth-child(2){
            font-size: 10px;
            opacity: 0.6;
        };
    }

    .ground__contact__details__separator{
        height: 1px;
        width: 100%;
        background: rgba(240, 240, 240, 0.20);
    }

    .ground__contact__task__details__container{
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 12px;
        span{
            font-size: 14px;
            color: #F0F0F0;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .ground__contact__task__details{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            span{
                color: #F0F0F0;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            };
            span:nth-child(1){
                font-size: 12px;
            };
            span:nth-child(2){
                font-size: 10px;
                opacity: 0.6;
            };
        }

        .ground__contact__event__card{
            display: flex;
            padding: var(--8, 8px);
            align-items: flex-start;
            gap: var(--4, 4px);
            align-self: stretch;
            justify-content: space-between;
            gap: 10px;
            width: 100%;
            .card__left__section{
                width: 45%;
                display: flex;
                flex-direction: column;
                gap: 5px;
                span{
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #F0F0F0;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                .contact__status__badge{
                    width: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    color: #F0F0F0;
                    padding: var(--4, 4px);
                    border-radius: var(--4, 4px);
                    background: rgba(240, 240, 240, 0.20);
                }
            };
            .card__right__section{
                width: 55%;
                display: flex;
                flex-direction: column;
                text-align: end;
                gap: 5px;
                span{
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #F0F0F0;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
                span:nth-child(2){
                    opacity: 0.6;
                    font-size: 10px;
                }
            }

            &.upcomming{
                border-radius: var(--4, 4px);
                background: rgba(240, 240, 240, 0.05);
            }
            &.ongoing{
                border-radius: var(--4, 4px);
                border: 0.5px solid rgba(204, 245, 78, 0.40);
                background: rgba(204, 245, 78, 0.10);
                .card__right__section {
                    span:nth-child(1){
                        color: #CCF54E;
                    }
                }
            }
            &.completed{
                border-radius: var(--4, 4px);
                border: 0.5px solid rgba(240, 240, 240, 0.20);
            }
        }
    }
}

.contact__modal__container{
    height: 100%;
    width: calc(98vw - 20.2vw);
    background: rgba(0, 0, 0, 0.20);
    backdrop-filter: blur(20px);
    display: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99; 
    animation: scale_out 0.3s ease-in-out forwards;
    &.show__contact__modal{
        display: flex;
        animation: scale_in 0.3s ease-in-out forwards; 
    }

    .contact__visualization__container{
        width: 798px;
        height: 479px;
        flex-shrink: 0;
        border-radius: var(--4, 4px);
        border: 0.5px solid rgba(240, 240, 240, 0.40);
        // background: url('/public/operate.png');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .close__btn{
            position: absolute;
            top: 20px;
            right: 20px;
            background: transparent;
            border-radius: 100%;
            background: rgba(240, 240, 240, 0.20);
            height: 30px;
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 99;
        }
    }
}

.minimize__maximize__btn{
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    width: var(--40, 40px);
    height: var(--40, 40px);
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    border: 0.5px solid rgba(240, 240, 240, 0.20);
    background: rgba(0, 0, 0, 0.50);
    box-shadow: 0px 4px 8px 0px rgba(160, 160, 160, 0.08);
    backdrop-filter: blur(5px);
    z-index: 99;
    &.small{
        bottom: 10px;
        right: 10px;
        padding: 6px;
        width: var(--24, 24px);
        height: var(--24, 24px);
    }
}

@keyframes scale_in {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale_out {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

.loading__container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    background: #191A1A;
    z-index: 999;
}