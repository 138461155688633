.review-schedule {
    position: absolute;
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    right: 1px;
    top: 85px;
    width: 700px;
    height: 650px;
    border-radius: 8px;
    border: 1px solid rgba(240, 240, 240, 0.20);
    background: rgba(0, 0, 0, 0.70);
    backdrop-filter: blur(50px);
    z-index: 100;
}

.draft-schedule {
    @extend .review-schedule;
    top: 100px;
    width: 750px;
    background: rgba(0, 0, 0, 0.80);
}

.list-schedule {
    @extend .review-schedule;
    border-radius: 0px 0px 0px 8px;
    background: rgba(0, 0, 0, 0.50);
}

.schedule__details__section {
    padding: 4px 12px;
}

.schedule_list_item {
    margin: 4px 0;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 10px 0px rgba(240, 240, 240, 0.05);
}

.schedule_list_section {
    width: 100%
};

.schedule_sub_text {
    color: #F0F0F0;
    font-size: 12px;
    font-weight: 300;
}

.schedule_date {
    width: 240px;
    align-content: center;
}

.schedule_state_div {
    width: 150px; 
    align-self: center;
}

.schedule_state {
    padding: 2px 8px;
    border-radius: 18px;
    width: fit-content;
    justify-self: center;

    p {
        font-size: 12px;
        font-style: italic;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 300;
    }
}

.schedule_state_blue {
    color: #FFFFFF;
    border: 1px solid #2465B2;
    background: rgba(36, 101, 178, 0.32);
}

.schedule_state_green {
    color: #CCF54E;
    border: 1px solid rgba(204, 245, 78, 0.30);
    background: rgba(204, 245, 78, 0.20);
}

.schedule_state_red {
    color: #F33;
    border: 1px solid rgba(255, 51, 51, 0.30);
    background: rgba(255, 51, 51, 0.20);
}

.schedule_state_grey {
    color: #F0F0F0;
    border: 1px solid rgba(240, 240, 240, 0.10);
    background: rgba(240, 240, 240, 0.20);
}

.schedule_state_orange {
    color: #F2A93B;
    border: 1px solid rgba(242, 169, 59, 0.10);
    background: rgba(242, 169, 59, 0.20);
}

.review-schedule-title {
    display: flex;
    font-size: 16px;
}

.review-schedule-title-icon {
    align-self: center;

    svg {
        path {
            stroke: #CCF54E;
            stroke-width: 0.55px;
        }
    }
}

.review-schedule-title-text {
    margin: 0;
    padding-left: 10px;
    color: #F0F0F0;
    font-size: 16px;
    font-weight: 500;
}

.review-schedule-orders-summary {
    display: flex;
}

.review-schedule-close-action {
    height: 24px;
    width: 24px;
    align-content: center;
    cursor: pointer;

    img {
        height: 14px;
        width: 14px;
    }
}

.review-schedule-orders-text {
    font-size: 12px;
    font-style: italic;
    margin-right: 10px;
    align-self: center; 
}

.review-schedule-header {
    display: flex;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    font-size: 12px;
    border-bottom: 0.5px solid rgba(240, 240, 240, 0.60);
    backdrop-filter: blur(50px);
    height: 40px;
}

.schedule-table-section {
    font-size: 12px;
    height: auto;
}

.schedule-table {
    width: 100%;
}

.schedule-table-header {
    background: rgba(240, 240, 240, 0.10);
}

.schedule-table-header th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.schedule-table-content {
    display: block;
    max-height: 520px;
    overflow-y: auto;
}

.schedule-table-row {
    display: flex;
    padding: 8px 13px;
    align-items: center;
    height: 30px;
    align-self: stretch;
    justify-content: space-between;
}

.schedule-table-column-width:nth-child(1) {
    width: 35%;
}

.schedule-table-column-width:nth-child(2) {
    width: 25%;
}

.schedule-table-column-width:nth-child(3) {
    width: 20%;
}

.schedule-table-column-width:nth-child(4) {
    width: 20%;
}

.table-sort {
    background: transparent;
    margin-left: 2px;

    .filter-col {
        height: 24px;
        width: 14px;
    }

    .sort-col {
        height: 24px;
        width: 20px;
    }
}

.search-popup {
    position: fixed;
    top: 2%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    font-size: 12px;
}

.search-popup-content {
    display: flex;
    align-items: center;
    gap: 10px;
}

.search-popup-input {
    width: 200px;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #000000;
}

.time-column {
    color: rgba(240, 240, 240, 0.60);
}

.schedule-table-column {
    color: #F0F0F0;
}

.assignment-icon {
    margin-right: 6px;
}

.review-schedule-actions {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 8px;
}

.review-schedule-button {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}

.review-schedule-actions-group {
    display: flex;
    gap: 10px;
}

.ps-btn-light {
    border-radius: 4px;
    background: #45570D;
}

.ps-btn-dark {
    color: rgba(240, 240, 240, 0.50);
    border-radius: 4px;
    border: 0.5px solid rgba(240, 240, 240, 0.50);
    background: transparent;
}

.loading-schedule-msg {
    margin: 10px;
}

.schedule-btn {
    padding: 8px;
    margin: 0;
    background: transparent;

    svg {
        height: 28px;
        width: 28px;
    }
}

.schedule-btn.active {
    border-radius: 4px;
    border: 0.5px solid #CCF54E;
    background: rgba(204, 245, 78, 0.10);

    svg {
        path {
            stroke: #CCF54E;
            stroke-width: 0.55px;
        }
    }
}

.gradient-border {
    position: relative;
    height: 8px;
    /* Total height: 4px spacing + 4px border */
    margin: 4px 0;
    /* 4px spacing above and below */
}

.gradient-border::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    /* The actual border thickness */
    background: linear-gradient(to right, #d3d3d3, #808080, #d3d3d3);
    /* Gray gradient */
    transform: translateY(-50%);
}

.GenerateScehduleIcon {
    height: 22px;
    width: 22px;
    background-color: rgb(75, 71, 71);
    border-radius: 12px;
    padding: 3px;
}

.three-dot-menu-popup {
    position: absolute;
    padding: 8px;
    z-index: 10;
    right: 35px;
    top: 13px;
    font-size: 12px;
    color: #BBBECA;
    border-radius: 8px;
    border: 1px solid rgba(240, 240, 240, 0.20);
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(20px);
}

.three-dot-menu-popup ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.three-dot-menu-popup li {
    padding: 2px 4px;
    cursor: pointer;
    transition: 300ms;
}

.three-dot-menu-popup li:hover {
    scale: 1.1;
}

.orderThreeDotMenuContainer {
    width: 55px;
    display: flex;
    justify-content: center;
}

.invisible-placeholder {
    visibility: hidden;
    width: 100%;
    height: 24px;
}